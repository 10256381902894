import constants from '../../constants/constants'
const {
    CREATE_MEME_PUBLISH_REQUEST,
    CREATE_MEME_PUBLISH_SUCCESS,
    CREATE_MEME_PUBLISH_FAILURE,
    FETCH_FONT_REQUEST,
FETCH_FONT_SUCCESS,
FETCH_FONT_FAILURE,
} = constants;

const initialState = {

    publishDetails: {},
    fetchFontDetails:{}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_MEME_PUBLISH_REQUEST:
            return {
                ...state
            };
        case CREATE_MEME_PUBLISH_SUCCESS:
            return {
                ...state,
                publishDetails: { success: true, ...action.publishMemeAction }
            };
        case CREATE_MEME_PUBLISH_FAILURE:
            return {
                ...state,
                publishDetails: { success: false, ...action.error }
            };


            case FETCH_FONT_REQUEST:
                return {
                    ...state
                };
            case FETCH_FONT_SUCCESS:
                return {
                    ...state,
                    fetchFontDetails: { success: true, ...action.fetchFontAction }
                };
            case FETCH_FONT_FAILURE:
                return {
                    ...state,
                    fetchFontDetails: { success: false, ...action.error }
                };
        default:
            return state;
    }
}