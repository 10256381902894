import memeGateWay, { namuAuthenticator } from "../config/service";
import apiLink from "../../api/apiLink"
import apiCall from "../../api/apiCall"
const headerText = { 'Access-Control-Allow-Origin': '*' }


const getCategoryList = async () =>
    memeGateWay.get(apiLink.getCatagoryList);

const addCategory = params =>
    memeGateWay.post(apiLink.addCategory, params);

const deleteCategory = params =>

    memeGateWay.put(apiLink.deleteCategory, params);

export default {
    getCategoryList,
    addCategory,
    deleteCategory

};