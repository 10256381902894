import React, { Component } from 'react'
import { connect } from 'react-redux'
import dashboardAction from '../../actions/dashboardAction';
import memeMainAction from '../../actions/memeMainAction';
import history from '../config/history';

const mapStateToProps = (state) => ({
  totalCategoryAction: state.dashboard,
  totalMemeCountAction: state.dashboard,
  totalRevenueCountAction: state.dashboard

})
class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCatCount: 0,
      totalMemeCount: 0,
      totalRevenueCount: 0,
      toggle: true,
    }
  }

  componentDidMount = async () => {

    let token = localStorage.getItem("userToken")
    let paramData = {
      token: token
    }
    await this.props.totalCategories(paramData)
    await this.props.totalMemeCount(paramData)
    await this.props.totalRevenue(paramData)
    await this.setState({
      totalCatCount: this.props.totalCategoryAction && this.props.totalCategoryAction.totalCatCount && this.props.totalCategoryAction.totalCatCount.data && this.props.totalCategoryAction.totalCatCount.data.count,
      totalMemeCount: this.props.totalMemeCountAction && this.props.totalMemeCountAction.totalMemeCount && this.props.totalMemeCountAction.totalMemeCount.data && this.props.totalMemeCountAction.totalMemeCount.data.count,
      totalRevenueCount: this.props.totalRevenueCountAction && this.props.totalRevenueCountAction.totalRevenueCount && this.props.totalRevenueCountAction.totalRevenueCount.data &&this.props.totalRevenueCountAction.totalRevenueCount.data.total,

    })
  }
  toogleClick = async (toggleState) => {
    await this.props.updateToogle(toggleState)
    await this.setState({ toggle: !this.state.toggle })
  }
  logOut = () => {

    localStorage.removeItem('userToken');
    history.replace('/')

  }

  render() {
    return (
      <div class="dash-body">
        <div class="header">
          <div class="header-left">
            <button onClick={() => { this.toogleClick(this.state.toggle) }} id="sidebar-toggle" class="sidebar-toggle" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="title-con">
              <h1 class="page-title">
                Dashboard
              </h1>
            </div>
          </div>
          <div class="header-right">
            <div class="user-con">
              <a class="link-logout" style={{ cursor: "pointer" }} onClick={() => this.logOut()} >
                <i class="material-icons"> power_settings_new</i>
                Log Out
              </a>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="dashboard-wrapper">
            <div class="row">
              <div class="col-md-4">
                <div class="card update-card bg-c-yellow">
                  <div class="update-card-details">
                    <h6>Total Invitations</h6>
                    <h4>{this.state.totalMemeCount}</h4>
                  </div>
                  <div class="update-card-box">
                    <i class="material-icons">receipt</i>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card update-card bg-c-green">
                  <div class="update-card-details">
                    <h6>Revenue</h6>
                    <h4>$ {this.state.totalRevenueCount && this.state.totalRevenueCount.toFixed(2)}</h4>
                  </div>
                  <div class="update-card-box">
                    <i class="material-icons">equalizer</i>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card update-card bg-c-red">
                  <div class="update-card-details">
                    <h6>Total categories</h6>
                    <h4>{this.state.totalCatCount}</h4>
                  </div>
                  <div class="update-card-box">
                    <i class="material-icons">category</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, { ...dashboardAction, ...memeMainAction })(dashboard);
