const baseUrl = "https://invitationsapi.esimchavites.com/";
// const baseUrl = "https://memeapi.spericorn.com/";
// const baseUrl = process.env.REACT_APP_API_BASE_URL;
if (!baseUrl) {
    throw new Error("API Url is not provided.");
}

export default {
    login: baseUrl + "auth/login",
    deleteCategory: baseUrl + "api/category/softDelete",
    getAllMemeData: baseUrl + "api/category/listImages?",
    getCatagoryList: baseUrl + "api/category/list",
    addCategory: baseUrl + "api/category",
    publishData: baseUrl + "api/meme",
    uploadImage: baseUrl + "api/meme",
    getCat: baseUrl + "api/category/list",
    totalCategories: baseUrl + "api/category/count",
    totalMemeCount: baseUrl + "api/meme/count",
    totalRevenue: baseUrl + "api/transaction/revenue",
    changePassword: baseUrl + "api/admin/changepassword",
    deletememe: baseUrl + "api/meme/softDelete",
    setDefaultPrice: baseUrl + "api/admin/changeDefaultAmount",
    getDefaultPrice: baseUrl + "user/getDefaultAmount",
    uploadFont: baseUrl + "api/font",
    fetchFonts: baseUrl + "api/font",
    deleteFont: baseUrl + "api/font/softDelete",
    baseUrl
};
