import React from "react";
import { Route } from "react-router-dom"; 

const UserPublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => 
                        <Component /> 
                    }
                />
            );
        };
        
        export default UserPublicRoute;
