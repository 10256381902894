import constants from '../constants/constants';
import authServices from '../components/services/authServices';
import history from '../components/config/history';
import dashboardServices from '../components/services/dashboardServices';

const {
    TOTAL_CATEGORIES_REQUEST,
    TOTAL_CATEGORIES_SUCCESS,
    TOTAL_CATEGORIES_FAILURE,
    TOTAL_MEME_COUNT_REQUEST,
    TOTAL_MEME_COUNT_SUCCESS,
    TOTAL_MEME_COUNT_FAILURE,
    TOTAL_REVENUE_COUNT_REQUEST,
    TOTAL_REVENUE_COUNT_SUCCESS,
    TOTAL_REVENUE_COUNT_FAILURE,
} = constants

const totalCategories = (params) => {

    const request = totalCategoryAction => ({
        type: TOTAL_CATEGORIES_REQUEST,
        totalCategoryAction
    });
    const success = totalCategoryAction => ({
        type: TOTAL_CATEGORIES_SUCCESS,
        totalCategoryAction
    });
    const failure = error => ({
        type: TOTAL_CATEGORIES_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await dashboardServices.totalCategories(params)
            // console.log('res', res)
            if (res.status === 200) {
                const totalCategories = res.data;
                if (totalCategories.success) {
                    // localStorage.setItem("userToken",res.data.data.token)
                    dispatch(success(totalCategories));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(totalCategories));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

const totalMemeCount = (params) => {

    const request = totalMemeCountAction => ({
        type: TOTAL_MEME_COUNT_REQUEST,
        totalMemeCountAction
    });
    const success = totalMemeCountAction => ({
        type: TOTAL_MEME_COUNT_SUCCESS,
        totalMemeCountAction
    });
    const failure = error => ({
        type: TOTAL_MEME_COUNT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await dashboardServices.totalMemeCount(params)
            // console.log('res', res)
            if (res.status === 200) {
                const totalCategories = res.data;
                if (totalCategories.success) {
                    // localStorage.setItem("userToken",res.data.data.token)
                    dispatch(success(totalCategories));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(totalCategories));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const totalRevenue = (params) => {

    const request = totalRevenueCountAction => ({
        type: TOTAL_REVENUE_COUNT_REQUEST,
        totalRevenueCountAction
    });
    const success = totalRevenueCountAction => ({
        type: TOTAL_REVENUE_COUNT_SUCCESS,
        totalRevenueCountAction
    });
    const failure = error => ({
        type: TOTAL_REVENUE_COUNT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await dashboardServices.totalRevenue(params)
            // console.log('res', res)
            if (res.status === 200) {
                const totalCategories = res.data;
                if (totalCategories.success) {
                    // localStorage.setItem("userToken",res.data.data.token)
                    dispatch(success(totalCategories));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(totalCategories));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}


export default {
    totalCategories,
    totalMemeCount,
    totalRevenue
}