import React, { Component } from 'react'
import { connect } from 'react-redux'
import categoriesAction from '../../actions/categoriesAction';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import memeMainAction from '../../actions/memeMainAction';
import history from '../config/history';

const mapStateToProps = (state) => (console.log('Catstate', state), {
    category: state.categoryList
})

class categories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryListData: {},
            categoryListArray: [],
            categoryName: '',
            categoryid: '',
            Show: false,
            Delete: false,
            CategoryErr:"",
            toggle: true,
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.category.categoryList !== this.props.category.categoryList && this.props.category.categoryList.success) {
            // console.log("this.props.category.categoryList", this.props.category.categoryList)
            this.setState({
                categoryListArray: this.props.category.categoryList.data.categoryList
            })
        }
    };

    componentDidMount = async () => {
        await this.props.getCategoryList()
        // console.log("this.state.categoryListArray", this.state.categoryListArray)
    }

    addCategory = async (catName) => {
        if(!this.state.categoryName){
            this.setState({
                CategoryErr: "field is required"
            })
        }else{
            let duptrim=this.state.categoryName.trim()
        let paramsData = {
            name: duptrim
        }
        await this.props.addCategory(paramsData)
        await this.props.getCategoryList()

        this.setState({
            Show: false
        });
    }
    }
    deleteCategory = async (catName) => {
        let paramsData = {
            _id: this.state.categoryid
        }

        await this.props.deleteCategory(paramsData)
        await this.props.getCategoryList()

        this.setState({
            Delete: false
        });
    }

    handleChange = (e) => {
        this.setState({
            categoryName: e.target.value
        });
        if(!e.target.value){
            this.setState({
                CategoryErr: "field is required"
            })
        }else{
            this.setState({
                CategoryErr: ""
            })
    }
    }
    handleClose = (e) => {
        this.setState({
            Show: false,
            Delete: false,
        });
    }
    toogleClick = async (toggleState) => {
        await this.props.updateToogle(toggleState)
        await this.setState({ toggle: !this.state.toggle })
    }

    logOut =()=>{

        localStorage.removeItem('userToken');
        history.replace('/')
      
      }



    render() {

        return (
            <>
                <div class="dash-body">
                    <div class="header">
                        <div class="header-left">
                            <button onClick={() => { this.toogleClick(this.state.toggle) }}  id="sidebar-toggle" class="sidebar-toggle" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="navbar-toggler-icon icon-bar"></span>
                                <span class="navbar-toggler-icon icon-bar"></span>
                                <span class="navbar-toggler-icon icon-bar"></span>
                            </button>
                            <div class="title-con">
                                <h1 class="page-title">
                                    Categories
                                </h1>
                            </div>
                        </div>
                        <div class="header-right">
                            <div class="user-con">
                                <a class="link-logout" style={{cursor:"pointer"}} onClick={()=>this.logOut()}>
                                    <i class="material-icons"> power_settings_new</i>
                                    Log Out
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="dashboard-wrapper">
                            <div class="row">
                                <div class="card category add-category" onClick={() => this.setState({
                                    categoryName: "",
                                    Show: true
                                })}>
                                    <i class="material-icons">add_circle_outline</i>
                                    Add
                                </div>
                                {this.state.categoryListArray.map((item, index) => {
                                    return (
                                        <div class="card category category-item">
                                            {item.name}
                                            <a onClick={() => this.setState({ Delete: true, categoryid: item._id })} class="btn-category-reomove">
                                                <i class="material-icons">delete_outline</i>
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.Show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label for="category-name-field">Enter category</label>
                        <input type="text" class="form-control" value={this.state.categoryName} placeholder="Enter category" id="category-name-field" name="categoryName" onChange={(e) => this.handleChange(e)} />
                        <span style={{ color: "red" }} >{this.state.CategoryErr}</span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Cancel
                </Button>
                        <Button variant="primary" onClick={this.addCategory}>
                            Add
                </Button>
                    </Modal.Footer>
                </Modal>


                <Modal show={this.state.Delete} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete category</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure to delete this Category?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            No
                </Button>
                        <Button variant="primary" onClick={this.deleteCategory}>
                            Yes
                </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
export default connect(mapStateToProps, { ...categoriesAction,...memeMainAction })(categories);
