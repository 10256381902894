import apiLink from "../../api/apiLink"
import apiCall from "../../api/apiCall"
const headerText = { 'Access-Control-Allow-Origin': '*' }

const totalCategories = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        // data:{category: params.category},
        apiLink: apiLink.totalCategories,
        method: 'get',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText
        }
    }
    const totalCatagoryData = await apiCall.getApiCall(paramsData)
    // console.log('totalCatagoryData', totalCatagoryData)
    return totalCatagoryData;
}

const totalMemeCount = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        // data:{category: params.category},
        apiLink: apiLink.totalMemeCount,
        method: 'get',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText
        }
    }
    const totalMemeCountData = await apiCall.getApiCall(paramsData)
    // console.log('totalMemeCountData', totalMemeCountData)
    return totalMemeCountData;
}
const totalRevenue = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        // data:{category: params.category},
        apiLink: apiLink.totalRevenue,
        method: 'get',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText
        }
    }
    const totalRevenueCountData = await apiCall.getApiCall(paramsData)
    // console.log('totalRevenueCountData', totalRevenueCountData)
    return totalRevenueCountData;
}

export default {
    totalCategories,
    totalMemeCount,
    totalRevenue
};