import constants from '../constants/constants';
import authServices from '../components/services/authServices';
import history from '../components/config/history';

const {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
} = constants

const login = (params) => {

    const request = loginAction => ({
        type: LOGIN_REQUEST,
        loginAction
    });
    const success = loginAction => ({
        type: LOGIN_SUCCESS,
        loginAction
    });
    const failure = error => ({
        type: LOGIN_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await authServices.login(params)
            // console.log('res', res)
            if (res.status === 200) {
                const resetPassword = res.data;
                if (resetPassword.success) {
                    localStorage.setItem("userToken", res.data.data.token)
                    dispatch(success(resetPassword));
                    history.push('/dashboard');
                } else {
                    dispatch(failure(resetPassword));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

export default {
    login
}