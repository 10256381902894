import axios from "axios";
import { API_ROUTE, MAIN_ROUTE } from "./serviceConstants";

const memeGateWay = axios.create({
  baseURL: API_ROUTE,
  timeout: 80000
});

memeGateWay.interceptors.response.use(
  res => {
    return res;
  },
  function (res) {
    if (res.response && res.response.status === 401) {
      // localStorage.clear();
      // window.location.href = "/login";
    }
    // else if(res.response && res.response.status === 404){
    //     window.location.href = '/error*';
    // }
    return res;
  }
);
memeGateWay.interceptors.request.use(function (config) {
  const token = localStorage.getItem("userToken");
  // console.log('token', token)
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return config;
});
export default memeGateWay;

export const namuAuthenticator = axios.create({
  baseURL: API_ROUTE,
  timeout: 80000
});
