import constants from '../constants/constants';
import history from '../components/config/history';
import memeMainServices from '../components/services/memeMainServices';
import uploadImageServicce from '../components/services/uploadImageServicce';

const {
    GET_ALL_DATA_MEME_REQUEST,
    GET_ALL_DATA_MEME_SUCCESS,
    GET_ALL_DATA_MEME_FAILURE,
    UPLOAD_IMAGE_REQUEST,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
    GET_CAT_REQUEST,
    GET_CAT_SUCCESS,
    GET_CAT_FAILURE,
    DELETE_MEME_REQUEST,
    DELETE_MEME_SUCCESS,
    DELETE_MEME_FAILURE,
    TOGGLE_STATE
} = constants

const getAllCatagoryData = (params) => {

    const request = getAllMemeAction => ({
        type: GET_ALL_DATA_MEME_REQUEST,
        getAllMemeAction
    });
    const success = getAllMemeAction => ({
        type: GET_ALL_DATA_MEME_SUCCESS,
        getAllMemeAction
    });
    const failure = error => ({
        type: GET_ALL_DATA_MEME_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await memeMainServices.getAllCatagoryData(params)
            // console.log('res', res)
            if (res.status === 200) {
                const memeAllData = res.data;
                if (memeAllData.success) {
                    dispatch(success(memeAllData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(memeAllData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const uploadImage = (params) => {

    const request = uploadImageAction => ({
        type: UPLOAD_IMAGE_REQUEST,
        uploadImageAction
    });
    const success = uploadImageAction => ({
        type: UPLOAD_IMAGE_SUCCESS,
        uploadImageAction
    });
    const failure = error => ({
        type: UPLOAD_IMAGE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            console.log('params', Array.from(params))
            const res = await uploadImageServicce.uploadImage(params)
            console.log('uploadImage', res)
            if (res.status === 200) {
                const memeAllData = res.data;
                if (memeAllData.success) {
                    dispatch(success(memeAllData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(memeAllData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const uploadImageUpdate = (params) => {

    const request = uploadImageAction => ({
        type: UPLOAD_IMAGE_REQUEST,
        uploadImageAction
    });
    const success = uploadImageAction => ({
        type: UPLOAD_IMAGE_SUCCESS,
        uploadImageAction
    });
    const failure = error => ({
        type: UPLOAD_IMAGE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            // console.log('params', params)
            const res = await uploadImageServicce.uploadImageUpdate(params)
            // console.log('res', res)
            if (res.status === 200) {
                const memeAllData = res.data;
                if (memeAllData.success) {
                    dispatch(success(memeAllData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(memeAllData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

const getCat = (params) => {

    const request = getCatAction => ({
        type: GET_CAT_REQUEST,
        getCatAction
    });
    const success = getCatAction => ({
        type: GET_CAT_SUCCESS,
        getCatAction
    });
    const failure = error => ({
        type: GET_CAT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await memeMainServices.getCat(params)
            // console.log('res', res)
            if (res.status === 200) {
                const memeAllData = res.data;
                if (memeAllData.success) {
                    dispatch(success(memeAllData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(memeAllData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const deleteMeme = (params) => {

    const request = deleteMemeAction => ({
        type: DELETE_MEME_REQUEST,
        deleteMemeAction
    });
    const success = deleteMemeAction => ({
        type: DELETE_MEME_SUCCESS,
        deleteMemeAction
    });
    const failure = error => ({
        type: DELETE_MEME_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await memeMainServices.deleteMeme(params)
            // console.log('resdel', res)
            if (res.status === 200) {
                const MemeData = res.data;
                if (MemeData.success) {
                    dispatch(success(MemeData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(MemeData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

const updateToogle = (toggleState) => {
    const toogle = toogleAction => ({
        type: TOGGLE_STATE + toggleState,
        toogleAction
    })
    return async dispatch => {
        try {
            dispatch(toogle())
        } catch (e) {

        }
    }
}

export default {
    getAllCatagoryData,
    uploadImage,
    uploadImageUpdate,
    getCat,
    deleteMeme,
    updateToogle

}