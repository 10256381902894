import React from 'react'

const overlay = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: '999',
    pointerEvents: ' none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "rgba(0, 0, 0, 0.45)",
};

function Loader() {
    return (
        <div style={overlay}>
            <div class="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader