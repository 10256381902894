import constants from '../constants/constants';
import history from '../components/config/history';
import categoryListServices from '../components/services/categoryListServices';



const {
    GET_CATEGORY_LIST_REQUEST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAILURE,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAILURE,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE
} = constants


const getCategoryList = (params) => {

    const request = getCategoryListAction => ({
        type: GET_CATEGORY_LIST_REQUEST,
        getCategoryListAction
    });
    const success = getCategoryListAction => ({
        type: GET_CATEGORY_LIST_SUCCESS,
        getCategoryListAction
    });
    const failure = error => ({
        type: GET_CATEGORY_LIST_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await categoryListServices.getCategoryList(params)
            // console.log('res', res)
            if (res.status === 200) {
                const categoryData = res.data;
                if (categoryData.success) {
                    dispatch(success(categoryData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(categoryData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}


const addCategory = (params) => {

    const request = addCategoryAction => ({
        type: ADD_CATEGORY_REQUEST,
        addCategoryAction
    });
    const success = addCategoryAction => ({
        type: ADD_CATEGORY_SUCCESS,
        addCategoryAction
    });
    const failure = error => ({
        type: ADD_CATEGORY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await categoryListServices.addCategory(params)
            // console.log('res', res)
            if (res.status === 200) {
                const categoryData = res.data;
                if (categoryData.success) {
                    dispatch(success(categoryData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(categoryData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const deleteCategory = (params) => {

    const request = deleteCategoryAction => ({
        type: DELETE_CATEGORY_REQUEST,
        deleteCategoryAction
    });
    const success = deleteCategoryAction => ({
        type: DELETE_CATEGORY_SUCCESS,
        deleteCategoryAction
    });
    const failure = error => ({
        type: DELETE_CATEGORY_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await categoryListServices.deleteCategory(params)
            // console.log('resdel', res)
            if (res.status === 200) {
                const categoryData = res.data;
                if (categoryData.success) {
                    dispatch(success(categoryData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(categoryData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
export default {
    getCategoryList,
    addCategory,
    deleteCategory
}