import React from "react";
import { Route } from "react-router-dom";

const AdminPublicRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                <main class="main-container">
                    <Component />
                </main>
            }
        />
    );
};

export default AdminPublicRoute;
