
import React, { Component } from 'react'
import { connect } from "react-redux";
import authAction from '../../../actions/authAction';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import authAction from '../../actions/authAction';
// import Loader from '../../components/common/toast/Loader';
// import usePrevious from '../../common/prevProps';

const mapStateToProps = (state) => ({
    auth: state.auth
})

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            emailErrorMsg: '',
            passwordErrorMsg: '',
            loader: false,
            submit: false,
            authDetails: {}
        }
    }

    // async componentDidMount () {

    // }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.submit && prevProps.auth.loginDetails !== this.props.auth.loginDetails && !this.props.auth.loginDetails.success) {
            alert(this.props.auth.loginDetails.message)
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if(this.state.authDetails.loginDetails&& this.state.authDetails.loginDetails.success===false){
    //         alert(this.state.authDetails.loginDetails&& this.state.authDetails.loginDetails.message)
    //     }
    //     console.log('this.state.authDetails', this.state.authDetails.loginDetails&& this.state.authDetails.loginDetails.success)
    // }

    login = async (e) => {
        this.setState({ submit: false })
        /* eslint-disable */
        // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?(\.\w{2,3})+)*(\.\w{2,3})+$/;
        /* eslint-enable */
        e.preventDefault();
        if (this.state.email.trim() === '') {
            this.setState({ emailErrorMsg: 'Please enter email' })
        } else if (this.state.email !== '' && !reg.test(this.state.email)) {
            this.setState({ emailErrorMsg: 'Please enter valid email' })
        } else if (this.state.password.trim() === '') {
            this.setState({ passwordErrorMsg: 'Please enter password' })
        } else {
            this.setState({ loader: true, submit: true })
            const loginData = {
                email: this.state.email,
                password: this.state.password.trim()

            }
            await this.props.login(loginData)
            await this.setState({
                authDetails: this.props.auth
            })
            this.setState({ loader: false })
            //api for login
        }
    }
    handleChange = (e) => {
        if (e.target.name === 'email') {
            this.setState({ email: e.target.value, emailErrorMsg: '' })
        } else if (e.target.name === 'password') {
            this.setState({ password: e.target.value, passwordErrorMsg: '' })
        }
    }

    render() {
        // console.log('this.state', this.state.authDetails)
        return (
            <div className="auth-container">
                <div className="logo-con">
                    <a href="#">
                        <img src="assets/images/logo-login.png" alt="logo" />
                    </a>
                </div>
                <div className="auth-box sign-in">
                    <h1 className="auth-title">Sign In</h1>
                    <form onSubmit={this.login}>
                        <div className="form-group">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" placeholder="Email" id="" name="email" onChange={(e) => this.handleChange(e)} />
                            <span style={{ color: 'red' }}>{this.state.emailErrorMsg}</span>
                        </div>
                        <div className="form-group">
                            <label for="pwd">Password</label>
                            <input type="password" class="form-control" placeholder="Password" id="" name="password" onChange={(e) => this.handleChange(e)} />
                            <span style={{ color: 'red' }}>{this.state.passwordErrorMsg}</span>
                        </div>
                        <div className="form-action">
                            <button type="submit" class="btn btn-primary btn-primary-gradient btn-block">Login</button>
                        </div>
                    </form>
                    {/* <div class="text-center link-footer">
                        <p>
                            <a href="#">Forget Password</a>
                        </p>
                    </div> */}
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps, { ...authAction })(Login);