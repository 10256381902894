import constants from '../constants/constants';
import history from '../components/config/history';
import createMemeServices from '../components/services/createMemeServices';


const {
    CREATE_MEME_PUBLISH_REQUEST,
    CREATE_MEME_PUBLISH_SUCCESS,
    CREATE_MEME_PUBLISH_FAILURE,
    FETCH_FONT_REQUEST,
FETCH_FONT_SUCCESS,
FETCH_FONT_FAILURE,
} = constants

const publishData = (params) => {

    const request = publishMemeAction => ({
        type: CREATE_MEME_PUBLISH_REQUEST,
        publishMemeAction
    });
    const success = publishMemeAction => ({
        type: CREATE_MEME_PUBLISH_SUCCESS,
        publishMemeAction
    });
    const failure = error => ({
        type: CREATE_MEME_PUBLISH_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            console.log('Publishparams', params)
            const res = await createMemeServices.publishData(params)
            console.log('Publishres', res)
            if (res.status === 200) {
                const memeAllData = res.data;
                if (memeAllData.success) {
                    dispatch(success(memeAllData));
                    alert("Data Published")
                    // history.push('/memes');
                    window.location.href = '/memes'
                } else {
                    dispatch(failure(memeAllData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

const fetchFonts = (params) => {

    const request = fetchFontAction => ({
        type: FETCH_FONT_REQUEST,
        fetchFontAction
    });
    const success = fetchFontAction => ({
        type: FETCH_FONT_SUCCESS,
        fetchFontAction
    });
    const failure = error => ({
        type: FETCH_FONT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            // console.log('Publishparams', params)
            const res = await createMemeServices.fetchFonts(params)
            // console.log('Publishres', res)
            if (res.status === 200) {
                const fetchFontsData = res.data;
                if (fetchFontsData.success) {
                    dispatch(success(fetchFontsData));
                    // alert("Data Published")
                    // history.push('/memes');
                    // window.location.href = '/memes'
                } else {
                    dispatch(failure(fetchFontsData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

export default {
    publishData,
    fetchFonts
}