import apiLink from "../../api/apiLink"
import apiCall from "../../api/apiCall"
import memeGateWay, { namuAuthenticator } from "../config/service";
const headerText = { 'Access-Control-Allow-Origin': '*' }


const getAllCatagoryData = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        data: {
            category: params.category,
            limit: params.limit,
            offset: params.offset,
        },
        apiLink: apiLink.getAllMemeData,
        method: 'get',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText
        }
    }
    const allCatagoryData = await apiCall.getApiCall(paramsData)
    // console.log('allCatagoryData', allCatagoryData)
    return allCatagoryData;
}

const getCat = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        // data: loginData,
        apiLink: apiLink.getCat,
        method: 'get',
        headers: {
            Authorization: `Bearer ${params}`,
            headerText
        }
    }
    const getAllCategory = await apiCall.getApiCall(paramsData)
    // console.log('getAllCategory', getAllCategory)
    return getAllCategory;
}
const deleteMeme = params =>

    memeGateWay.put(apiLink.deletememe, params);
export default {
    getAllCatagoryData,
    getCat,
    deleteMeme
};