import { combineReducers } from 'redux'
 import authReducer from './authReducer'
 import memeMainReducer from './memeMainReducer'
 import createMemeReducer from './createMemeReducer'
 import categoryListReducer from './categoryListReducer'
import dashboardReducer from './dashboardReducer'
import settingsReducer from './settingsReducer'

const rootReducer = combineReducers({
 
    auth:authReducer,
    memeMain:memeMainReducer,
    categoryList:categoryListReducer,
    createMeme:createMemeReducer,
    dashboard:dashboardReducer,
    settings:settingsReducer



})

export default rootReducer;