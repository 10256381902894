import React, { Component } from 'react'
import history from '../config/history'
import { connect } from 'react-redux'
import createMemeAction from '../../actions/createMemeAction';
import memeMainAction from '../../actions/memeMainAction';
import domtoimage from 'dom-to-image';
import Loader from './Loader';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ScriptTag from 'react-script-tag';
import RenderColorPalette from './RenderColor';
import { createGlobalStyle } from 'styled-components';
import EditableElement from './EditableElement';
import apiLink from '../../api/apiLink';
import { SketchPicker } from 'react-color';

const mapStateToProps = (state) => (console.log('CreateMemestate'), {
    publishedmeme: state.memeMain,
    fetchFontAction: state.createMeme
});

class createMeme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editedDiv: "",
            id: "",
            imgProperty: "",
            price: 0,
            Errprice: "",
            blankimg: "f",
            updating: "",
            categoryId: "",
            imageData: "",
            imageFile: "",
            loader: false,
            update: false,
            renderScript: true,
            imgPath: "",
            toggle: true,
            fontList: [],
            fontLink: '',
            fontName: 'myFont',
            contentLoader: false,
            textList: [],
            stageHeight: 0,
            stageWidth: 0,
            selectedText: null,
            showColorPalette: false
        }
        this.myRef = React.createRef()
        this.artboardRef = React.createRef()
    }

    componentDidMount = async () => {
        await this.setState({
            loader: true,
            contentLoader: true
        });
        await this.props.fetchFonts()
        await this.setState({
            fontList: this.props.fetchFontAction && this.props.fetchFontAction.fetchFontDetails &&
                this.props.fetchFontAction.fetchFontDetails.data
                && this.props.fetchFontAction.fetchFontDetails.data.list
        });
        let memeDetails = localStorage.getItem("memeDetails");
        // this.myRef.current.click();

        // console.log(memeDetails, history.location.state)
        if (memeDetails) {
            memeDetails = JSON.parse(memeDetails)
            await this.setState({
                id: memeDetails.id,
                textList: memeDetails.imgProperty,
                price: memeDetails.price,
                file: memeDetails.imageNew,
                updating: memeDetails.updating,
                imgPath: memeDetails.imgPath
            });
        } else {
            await this.setState({
                id: history.location.state && history.location.state.id,
                textList: history.location.state && history.location.state.imgProperty,
                price: history.location.state && history.location.state.price,
                file: history.location.state && history.location.state.imageFile,
                updating: history.location.state && history.location.state.updating,
                imgPath: history.location.state && history.location.state.imgPath
            });
        }
        let isReload = localStorage.getItem("isReload")
        if (!isReload) {
            localStorage.setItem("isReload", true)
            window.location.reload(true)
        }
        window.addEventListener('resize', this.resizeListerner);

        await this.setState({
            stageHeight: document.getElementById("editedElement").offsetHeight,
            stageWidth: document.getElementById("editedElement").offsetWidth
        })
        await this.artboardRef.current.click()
        setTimeout(async () => {
            await this.setState({ contentLoader: false })
        }, 500);
        console.log("IDI mioun")
    }
    resizeListerner = async () => {
        if (this.state.stageWidth !== this.artboardRef.current.offsetWidth || this.state.stageHeight !== this.artboardRef.current.offsetHeight) {
            await this.setState({
                stageHeight: document.getElementById("editedElement").offsetHeight,
                stageWidth: document.getElementById("editedElement").offsetWidth
            })
            await this.artboardRef.current.click()
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeListerner);

        localStorage.removeItem("isMemeReload");
    }

    async componentDidUpdate() {
        // console.log('this.props', this.props)
        this.resizeListerner()
    }

    blob2file = (blobData) => {
        const fd = new FormData();
        fd.set('a', blobData);
        return fd.get('a');
    };

    convertBase64ToFile = (image) => {
        let imageFile = image.split(',');
        const byteString = atob(imageFile[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }
        let imageFileType = imageFile[0].split(";")[0].split(":")
        return new Blob([ab], {
            type: imageFileType.length > 1 ? imageFileType[1] : 'image/jpeg',
        });
    };

    handleupdated = async () => {
        if (this.state.publishstatue === true) {
            await this.setState({ update: false, loader: false })
            window.location.href = "/memes";
        } else {
            await this.setState({ update: false, loader: false })
        }
    };

    publish = async () => {
        // await this.setState({ selectedText: null })
        if (this.state.price === "") {
            await this.setState({ price: 0 })
        }
        if (this.state.Errprice === "") {
            this.artboardRef.current.click()
            if (this.state.updating === "upload") {
                this.setState({ loader: true })
                const objMeme = this;
                const imageElement = document.getElementById('artboard');
                // console.log("ImageELemet", imageElement)
                domtoimage.toPng(imageElement)
                    .then(function (dataUrl) {
                        // console.log("Data", dataUrl)
                        let filepath = objMeme.convertBase64ToFile(dataUrl);
                        let imageFile = objMeme.blob2file(filepath)
                        objMeme.setState({ imageData: dataUrl, imageFile: imageFile }, async () => {
                            let test = document.getElementById('editedElement').innerHTML;
                            localStorage.setItem("editedElement", test)
                            let logDiv = localStorage.getItem("editedElement")
                            if (logDiv === "") {
                                await objMeme.setState({ editedDiv: "<div><div>" })
                            } else {
                                await objMeme.setState({ editedDiv: logDiv })
                            }
                            let uploadDetails = new FormData()
                            // console.log(objMeme.state.imageFile, objMeme.state.imageData)
                            await uploadDetails.append("file", objMeme.state.file)
                            await uploadDetails.append("meme", objMeme.state.imageFile)
                            await uploadDetails.append("data", JSON.stringify({
                                categoryId: objMeme.state.id,
                                price: objMeme.state.price,
                                imgProperty: JSON.stringify(objMeme.state.textList),
                            }))
                            // console.log("Upload File", Array.from(uploadDetails));
                            await objMeme.props.uploadImage(uploadDetails)
                            // localStorage.setItem("adminSavedMemes", JSON.stringify(memeArray))
                            await objMeme.setState({
                                publishstatue: objMeme.props.publishedmeme && objMeme.props.publishedmeme.uploadImageDetails && objMeme.props.publishedmeme.uploadImageDetails.success
                            });
                            await objMeme.setState({ update: true })
                        })
                    })
                    .catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    });
            } else {
                this.setState({ loader: true })
                const objMeme = this;
                const imageElement = document.getElementById('artboard');
                domtoimage.toPng(imageElement)
                    .then(function (dataUrl) {
                        let filepath = objMeme.convertBase64ToFile(dataUrl);
                        let imageFile = objMeme.blob2file(filepath)
                        objMeme.setState({ imageData: dataUrl, imageFile: imageFile }, async () => {
                            let test = document.getElementById('editedElement').innerHTML;
                            localStorage.setItem("editedElement", test)
                            let logDiv = localStorage.getItem("editedElement")
                            if (logDiv === "") {
                                await objMeme.setState({ editedDiv: "<div><div>" })
                            } else {
                                await objMeme.setState({ editedDiv: logDiv })
                            }
                            let uploadDetails = new FormData()
                            await uploadDetails.append("meme", objMeme.state.imageFile)
                            await uploadDetails.append("data", JSON.stringify({
                                _id: objMeme.state.id,
                                price: objMeme.state.price,
                                imgProperty: JSON.stringify(objMeme.state.textList),
                            }))
                            // localStorage.setItem("adminSavedMemes", JSON.stringify(meme))
                            await objMeme.props.uploadImageUpdate(uploadDetails)
                            await objMeme.setState({
                                publishstatue: objMeme.props.publishedmeme && objMeme.props.publishedmeme.uploadImageDetails && objMeme.props.publishedmeme.uploadImageDetails.success
                            });
                            await objMeme.setState({ update: true })
                        })
                    })
                    .catch(function (error) {
                        console.error('oops, something went wrong!', error);
                    });
            }
        }
    };

    toogleClick = async (toggleState) => {
        await this.props.updateToogle(toggleState)
        await this.setState({ toggle: !this.state.toggle })
    };

    logOut = () => {
        localStorage.removeItem('userToken');
        history.replace('/')
    };

    imageLoad = async () => {
        await this.setState({ loader: true })
        await this.resizeListerner();
        await this.setState({ loader: false, contentLoader: false })
        this.artboardRef.current.click();
    };

    addText = () => {
        this.setState((prevState) => {
            let newState = [...prevState.textList];
            newState.push({ fontSize: 24, value: '<div>Your Text Here</div>', fontFamily: 'roboto', color: '#000000', fontStyle: '', align: 'left', lineHeight: 1, bold: false, italic: false, top: 0, left: 0, vCenter: false, hCenter: false });
            this.setState({
                textList: newState
            })
        })
    }
    handleClick = async (e, i) => {
        // console.log(i)
        if (e.stopPropagation) {
            e.stopPropagation();   // W3C model
        } else {
            e.cancelBubble = true; // IE model
        }
        await this.setState({ selectedText: i });
        // console.log(e.target.absolutePosition(), i)
    };
    handleChange = (e) => {
        // console.log(e.target.value, e.target.name, e.target.checked)
        let newValue = e.target.value
        let name = e.target.name;
        let checked = e.target.checked;
        let selectedText = this.state.selectedText;
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                if (newArray[prevState.selectedText]) {
                    // console.log(newArray[prevState.selectedText][name])
                    if (name === "lineHeight")
                        newArray[prevState.selectedText][name] = newValue;
                    else if (name === "bold" || name === "italic") {
                        newArray[prevState.selectedText][name] = checked;
                    }
                    else
                        newArray[prevState.selectedText][name] = newValue;
                    this.setState({
                        textList: newArray
                    })
                }
            })
        }
        this.artboardRef.current.click();
        this.setState({ selectedText })
    }
    handleColorChange = color => {
        // console.log(e.target.value, e.target.name, e.target.checked);
        if (this.state.selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                // console.log(newArray[prevState.selectedText][name])
                newArray[prevState.selectedText].color = color.hex;
                this.setState({
                    textList: newArray
                })
            })
        }
    }
    handleCheckBox = (e) => {
        // console.log(e.target.value, e.target.name, e.target.checked)
        let newValue = e.target.value
        let name = e.target.name;
        let checked = e.target.checked;
        let selectedText = this.state.selectedText;
        if (selectedText !== null) {
            this.setState((prevState) => {
                let newArray = [...prevState.textList];
                // console.log(newArray[prevState.selectedText][name])
                if (checked)
                    newArray[prevState.selectedText][name] = newValue;
                else
                    newArray[prevState.selectedText][name] = "";
                this.setState({
                    textList: newArray
                })
            })
        }
    }
    handleBoldItalicChange = (e) => {
        // console.log(e.target.value, e.target.name, e.target.checked)
        let newValue = e.target.value
        let name = e.target.name;
        let checked = e.target.checked;
        this.setState((prevState) => {
            let newArray = [...prevState.textList];
            newArray[prevState.selectedText][name] = checked;
            this.setState({
                textList: newArray
            })
        })
    }
    editText = value => {
        if (this.state.selectedText !== null) {
            let array = [...this.state.textList];
            // console.log(this.state.textList);
            array[this.state.selectedText].value = value;
            this.setState({
                textList: array
            })
        }
    }
    handleDragEnd = (x, y) => {
        let array = [...this.state.textList];
        let selectedText = this.state.selectedText;
        if (selectedText !== null) {
            array[selectedText].top = y;
            array[selectedText].left = x;
            array[selectedText].vCenter = false;
            array[selectedText].hCenter = false;
            this.setState({
                textList: array
            })
        }
    }
    deleteText = () => {
        let array = [...this.state.textList];
        let selectedText = this.state.selectedText;
        if (selectedText !== null && array.length > 0) {
            let newArray = array.filter((array, index) => {
                return index !== selectedText
            })
            // console.log("Array", newArray);
            this.setState({
                textList: newArray,
                selectedText: 0
            })
        }
    }
    align = async position => {
        let array = [...this.state.textList];
        let selectedText = this.state.selectedText;
        if (selectedText !== null) {
            if (position === "vCenter") {
                array[selectedText]['top'] = 50;
                array[selectedText]['vCenter'] = true
            }
            else {
                array[selectedText]['left'] = 50;
                array[selectedText]['hCenter'] = true
            }
            await this.setState({ textList: array })
        }
    }
    handlePriceChange = async (e) => {
        this.setState({ price: e.target.value })
        let priceVaild = /^\d+(?:[.,]\d+)*$/
        if (!e.target.value) {
            await this.setState({ price: " ", Errprice: "" },
            )
        } else if (!priceVaild.test(e.target.value.trim())) {
            this.setState({
                Errprice: "Please enter a valid price"
            });
        } else {
            this.setState({
                Errprice: ""
            });
        }
    };
    render() {
        const { fontList } = this.state;
        const GlobalStyle = createGlobalStyle`${fontList && fontList.map((item, index) => `@font-face {
            font-family: '${item.name}';
            src: url(${apiLink.baseUrl}${item.path[0].original}) format('truetype');
          }`)}`;

        if (this.state.price === "") {
            this.setState({
                price: 0
            });
        }
        let { textList, selectedText = 0, stageWidth, stageHeight, showColorPalette } = this.state;
        let selectedFontSize = 24;
        let selectedFont = 'roboto';
        let selectedColor = '#FFF';
        let selectedTextUnderLine = "";
        let selectedLineHeight = "1";
        let selectedFontBold = false;
        let selectedFontItalic = false;
        let selectedTextAlignment = "left";

        if (selectedText !== null && textList.length > 0 && selectedText !== undefined) {
            selectedFontSize = textList[selectedText].fontSize;
            selectedFont = textList[selectedText].fontFamily;
            selectedColor = textList[selectedText].color;
            selectedTextUnderLine = textList[selectedText].textDecoration;
            selectedLineHeight = textList[selectedText].lineHeight;
            selectedFontBold = textList[selectedText].bold;
            selectedFontItalic = textList[selectedText].italic;
            selectedTextAlignment = textList[selectedText].align;
        }
        return (
            <>
                {this.state.loader && <Loader />}
                <GlobalStyle />
                <div className="dash-body" ref={this.myRef}>
                    <div className="header">
                        <div className="header-left">
                            <button onClick={() => {
                                this.toogleClick(this.state.toggle)
                            }} id="sidebar-toggle" className="sidebar-toggle" type="button" data-toggle="collapse"
                                aria-controls="navigation-index" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                            </button>
                            <div className="title-con">
                                <h1 className="page-title">
                                    Create Invitation
                                </h1>
                            </div>
                        </div>
                        <div className="header-right">
                            <div className="user-con">
                                <a className="link-logout" style={{ cursor: "pointer" }} onClick={() => this.logOut()}>
                                    <i className="material-icons"> power_settings_new</i>
                                    Log Out
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="editor-con">
                            <div className="artboard-con" onClick={() => this.setState({ selectedText: null, showColorPalette: false })} style={{ zIndex: this.state.contentLoader === true ? -1 : 0 }}>
                                <div className="artboard" id="artboard">
                                    {this.state.imgPath && this.state.imgPath.length === 0 ?
                                        <img src="assets/images/white-bg.jpg" alt="" onLoad={this.imageLoad} /> :
                                        <img id="bgimage" src={this.state.imgPath} alt="" onLoad={this.imageLoad} />

                                    }
                                    <div id="editedElement" className="parent align" ref={this.artboardRef}>
                                        {
                                            textList.map((text, idx) => {
                                                return (
                                                    <EditableElement text={text} index={idx} handleClick={(e) => this.handleClick(e, idx)} editText={this.editText} dragEnd={this.handleDragEnd} selected={selectedText} stageHeight={stageHeight} stageWidth={stageWidth} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="tools-con">
                                <div className="tools-head">
                                    Output Format
                                </div>
                                <div className="tools-block">
                                    <div className="btn-group-v">
                                        <button className="btn-tools btn-fill" onClick={this.addText}>
                                            <i className="material-icons">text_fields</i>
                                            Add Text
                                        </button>
                                        {/* <button id="editTextElement" className="btn-tools btn-fill">
                                            <i className="material-icons">edit</i> Edit Text
                                        </button> */}
                                    </div>
                                </div>
                                <div className="tools-block tools-block-editor">
                                    <div className="tools-block-title">
                                        Font
                                    </div>
                                    <div className="btn-group-v">
                                        <select className="form-control" name="fontFamily" value={selectedFont} onChange={(e) => this.handleChange(e)} >
                                            {this.state.fontList &&
                                                this.state.fontList.map((item, index) => {
                                                    return (
                                                        <option
                                                            value={item.name}
                                                            style={{ fontFamily: item.name }}
                                                            data-font={item.name}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}

                                            <option
                                                value="roboto"
                                                style={{ fontFamily: "roboto" }}
                                                data-font="roboto"
                                            >
                                                Roboto
                                            </option>
                                            <option
                                                value="lobster, cursive"
                                                style={{ fontFamily: "lobster, cursive" }}
                                                data-font="Lobster"
                                            >
                                                Lobster
                                            </option>
                                            <option
                                                value="poppins, sans-serif"
                                                style={{ fontFamily: "poppins, sans-serif" }}
                                                data-font="Poppins"
                                            >
                                                Poppins
                                            </option>
                                            <option
                                                value="sen, sans-serif"
                                                style={{ fontFamily: "sen, sans-serif" }}
                                                data-font="Sen"
                                            >
                                                Sen
                                            </option>
                                            <option
                                                value="inter, sans-serif"
                                                style={{ fontFamily: "inter, sans-serif" }}
                                                data-font="Inter"
                                            >
                                                Inter
                                            </option>
                                            <option
                                                value="open Sans, sans-serif"
                                                style={{ fontFamily: "open Sans, sans-serif" }}
                                                data-font="Open+Sans"
                                            >
                                                Open Sans
                                            </option>
                                            <option
                                                value="bellota, cursive"
                                                style={{ fontFamily: "bellota, cursive" }}
                                                data-font="Bellota"
                                            >
                                                Bellota
                                            </option>
                                            <option
                                                value="montserrat, sans-serif"
                                                style={{ fontFamily: "montserrat, sans-serif" }}
                                                data-font="Montserrat"
                                            >
                                                Montserrat
                                            </option>
                                            <option
                                                value="oswald, sans-serif"
                                                style={{ fontFamily: "oswald, sans-serif" }}
                                                data-font="Oswald"
                                            >
                                                Oswald
                                            </option>
                                            <option
                                                value="viaoda libre, cursive"
                                                style={{ fontFamily: "viaoda libre, cursive" }}
                                                data-font="Viaoda+Libre"
                                            >
                                                Viaoda Libre
                                            </option>
                                            {/* <option value="'Baloo Chettan 2', cursive" style={{ fontFamily: "Baloo Chettan 2, cursive" }} data-font="Baloo+Chettan+2">Baloo Chettan 2</option> */}
                                            <option
                                                value="merriweather, serif"
                                                style={{ fontFamily: "merriweather, serif" }}
                                                data-font="Merriweather"
                                            >
                                                Merriweather
                                            </option>
                                            <option
                                                value="playfair display, serif"
                                                style={{ fontFamily: "playfair display, serif" }}
                                                data-font="Playfair+Display"
                                            >
                                                Playfair Display
                                            </option>
                                            <option
                                                value="open sans condensed, sans-serif"
                                                style={{
                                                    fontFamily: "open sans condensed, sans-serif"
                                                }}
                                                data-font="Open+Sans+Condensed"
                                            >
                                                Open Sans Condensed
                                            </option>
                                            <option
                                                value="nunito, sans-serif"
                                                style={{ fontFamily: "nunito, sans-serif" }}
                                                data-font="Nunito"
                                            >
                                                Nunito
                                            </option>
                                            <option
                                                value="titillium web, sans-serif"
                                                style={{ fontFamily: "titillium web, sans-serif" }}
                                                data-font="Titillium+Web"
                                            >
                                                Titillium Web
                                            </option>
                                            <option
                                                value="quicksand, sans-serif"
                                                style={{ fontFamily: "quicksand, sans-serif" }}
                                                data-font="Quicksand"
                                            >
                                                Quicksand
                                            </option>
                                            <option
                                                value="josefin sans, sans-serif"
                                                style={{ fontFamily: "josefin sans, sans-serif" }}
                                                data-font="Josefin+Sans"
                                            >
                                                Josefin Sans
                                            </option>
                                            <option
                                                value="indie flower, cursive"
                                                style={{ fontFamily: "indie flower, cursive" }}
                                                data-font="Indie+Flower"
                                            >
                                                Indie Flower
                                            </option>
                                            {/* <option value="'Dancing Script', cursive" style={{ fontFamily: "Dancing Script, cursive" }} data-font="Dancing+Script">Dancing Script</option> */}
                                            <option
                                                value="pacifico, cursive"
                                                style={{ fontFamily: "pacifico, cursive" }}
                                                data-font="Pacifico"
                                            >
                                                Pacifico
                                            </option>
                                        </select>
                                    </div>
                                    <div className="btn-group-h-2">
                                        <div className="btn-group-h-col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="material-icons">
                                                            format_size
                                                        </i>
                                                    </span>
                                                </div>
                                                <input className="form-control" type="number" value={selectedFontSize} name='fontSize' onChange={e => this.handleChange(e)} />
                                            </div>
                                        </div>
                                        <div className="btn-group-h-col">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="material-icons">
                                                            format_line_spacing
                                                        </i>
                                                    </span>
                                                </div>
                                                <select className="form-control" value={selectedLineHeight || "1"} name='lineHeight' onChange={e => this.handleChange(e)}>
                                                    <option value="0.1">0.1x</option>
                                                    <option value="0.2">0.2x</option>
                                                    <option value="0.4">0.4x</option>
                                                    <option value="0.6">0.6x</option>
                                                    <option value="0.8">0.8x</option>
                                                    <option value="1.0">1x</option>
                                                    <option value="1.15">1.15x</option>
                                                    <option value="1.5">1.5x</option>
                                                    <option value="2">2x</option>
                                                    <option value="3">3x</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-group-h">
                                        <label class="btn-input-con" for="checkBold" onChange={(e) => this.handleChange(e)}>
                                            <input
                                                class="btn-input"
                                                id="checkBold"
                                                type="checkbox"
                                                name="bold"
                                                value="bold"
                                                checked={selectedFontBold}
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_bold</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="checkItalic" onChange={(e) => this.handleChange(e)}>
                                            <input
                                                class="btn-input"
                                                id="checkItalic"
                                                type="checkbox"
                                                name="italic"
                                                value="italic"
                                                checked={selectedFontItalic}
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_italic</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="checkUnderline" onChange={(e) => this.handleCheckBox(e)}>
                                            <input
                                                class="btn-input"
                                                id="checkUnderline"
                                                type="checkbox"
                                                name="textDecoration"
                                                checked={selectedTextUnderLine === "underline" ? true : false}
                                                value="underline"
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_underlined</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="radioAlignLeft">
                                            <input
                                                class="btn-input"
                                                id="radioAlignLeft"
                                                type="radio"
                                                name="align"
                                                checked={selectedTextAlignment === "left" ? true : false}
                                                value="left"
                                                onChange={e => this.handleChange(e)}
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_align_left</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="radioAlignCenter" >
                                            <input
                                                class="btn-input"
                                                id="radioAlignCenter"
                                                type="radio"
                                                checked={selectedTextAlignment === "center" ? true : false}
                                                name="align"
                                                onChange={e => this.handleChange(e)}
                                                value="center"
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_align_center</i>
                                            </span>
                                        </label>
                                        <label class="btn-input-con" for="radioAlignRight">
                                            <input
                                                class="btn-input"
                                                id="radioAlignRight"
                                                type="radio"
                                                checked={selectedTextAlignment === "right" ? true : false}
                                                onChange={e => this.handleChange(e)}
                                                name="align"
                                                value="right"
                                            />
                                            <span class="btn-input-style">
                                                <i class="material-icons">format_align_right</i>
                                            </span>
                                        </label>
                                    </div>
                                    <div className="tools-block-title-sub">
                                        Font Color
                                    </div>
                                    <div className="btn-group-h-2">
                                        <div className="btn-group-h-col">
                                            <div className="dropdown dropleft">
                                                <button id="addColorElement" className="btn-tools btn-fill"
                                                    // data-toggle="dropdown"
                                                    onClick={(e) => {
                                                        if (e.stopPropagation) {
                                                            e.stopPropagation();   // W3C model
                                                        } else {
                                                            e.cancelBubble = true; // IE model
                                                        } this.setState({ showColorPalette: !showColorPalette })
                                                    }}>
                                                    <span id="picked-color" className="picked-color" style={{ backgroundColor: selectedColor }}>&nbsp;</span>
                                                    <span id="picked-color-value"
                                                        className="picked-color-value"> {showColorPalette ? "Click To Hide" : selectedColor}</span>
                                                </button>
                                                {/*<div className="dropdown-menu color-palette">
                                                    <RenderColorPalette handleColorChange={this.handleColorChange} />
                                                </div>*/}
                                                {showColorPalette &&
                                                    <div style={{ position: 'absolute', top: 40, zIndex: 2 }}>
                                                        <SketchPicker onChange={this.handleColorChange} color={selectedColor} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tools-block-title">
                                        Layer
                                    </div>
                                    <div className="btn-group-h">
                                        <button
                                            style={{ display: "flex", justifyContent: "center", paddingTop: 5 }}
                                            id="deleteLayer"
                                            className="btn-tools"
                                            onClick={this.deleteText}
                                        >
                                            <i className="material-icons">delete_outline</i>
                                        </button>
                                        <button
                                            style={{ display: "flex", justifyContent: "center", paddingTop: 5 }}
                                            id="checkAlignV"
                                            className="btn-tools"
                                            onClick={() => this.align("vCenter")}
                                        >
                                            <i className="material-icons">vertical_align_center</i>
                                        </button>
                                        <button
                                            style={{ display: "flex", justifyContent: "center", paddingTop: 5 }}
                                            id="checkAlignH"
                                            className="btn-tools"
                                            onClick={() => this.align("hCenter")}
                                        >
                                            <i className="material-icons">vertical_align_center</i>
                                        </button>
                                    </div>
                                    <div className="tools-block-title">
                                        PRICE
                                    </div>
                                    <div className="input-group input-group-price">
                                        {/* <div className="input-group"> */}
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                $
                                                    </span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter price" name="price"
                                            value={this.state.price} onChange={(e) => {
                                                this.handlePriceChange(e)
                                            }} />
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.Errprice}</span>
                                </div>

                                <div className="tools-footer">
                                    <button onClick={() => this.publish()} className="btn btn-publish">Publish</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.update} onHide={this.handleClose}>
                    <Modal.Header closeButton onClick={this.handleupdated}>
                        <Modal.Title>Publish Invitation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.publishstatue ?
                            "Invitation has been successfully published" : "Publish Invitation failed"}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.publishstatue ?
                            <Button variant="success" onClick={this.handleupdated}>
                                ok
                            </Button> :
                            <Button variant="info" onClick={this.handleupdated}>
                                ok
                            </Button>}

                    </Modal.Footer>
                </Modal>
                <ScriptTag isHydrating={true} type="text/javascript" src="dragable.js" />
                {this.state.renderScript && <ScriptTag isHydrating={true} type="text/javascript" src="dragable.js" />}
            </>
        )
    }
}

export default connect(mapStateToProps, { ...createMemeAction, ...memeMainAction })(createMeme);
