import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../config/history";
import ScriptTag from "react-script-tag";
import settingsAction from "../../actions/settingsAction";
import memeMainAction from "../../actions/memeMainAction";
import createMemeAction from "../../actions/createMemeAction";
import Loader from "../dashboard/Loader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import dashboardAction from "../../actions/dashboardAction";
import InfiniteScroll from "react-infinite-scroll-component";
import $ from "jquery";
import { findDOMNode } from "react-dom";
import apiLink from "../../api/apiLink";

const mapStateToProps = state => ({
    getAllMemeAction: state.memeMain,
    getCatAction: state.memeMain,
    totalMemeCountAction: state.dashboard,
    getDefaultPriceAction: state.settings
});

class memes extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            logoutDiv: "",
            allMemeDetails: {},
            catDetails: [],
            imgSrc: "",
            imgSrcBase64: [],
            categoryId: "",
            price: "",
            getCatDetails: {},
            getCatList: [],
            getCat: {},
            categoryIdMain: "",
            categoryIdErrorMsg: "",
            showModal: true,
            showModalStyle: true,
            loader: true,
            renderScript: false,
            Delete: false,
            DeleteMemeId: "",
            totalMemeCount: 0,
            errPrice: "",
            Errimg: "",
            limit: 1000,
            offset: 0,
            fileData: [],
            memeCount: 10,
            toggle: true,
            errorStatus: false
        };
    }

    componentDidMount = async () => {
        let token = localStorage.getItem("userToken");
        const categoryVal = localStorage.getItem("categoryVal");
        let paramsData = {
            token: token,
            category: categoryVal,
            limit: this.state.limit,
            offset: this.state.offset
        };
        await this.props.getDefaultPrice(paramsData);

        let isReload = localStorage.getItem("isMemeReload");
        if (!isReload) {
            localStorage.setItem("isMemeReload", true);
            window.location.reload(true);
        }
        this.myRef.current.click();

        // let defaultPrice = localStorage.getItem("defaultPrice");
        this.setState({ loader: true });
        let formatedPrice = parseFloat(
            this.props.getDefaultPriceAction.getDefaultpericeDetails.data.defaultAmount
        );
        let formatedPriceNew = formatedPrice.toFixed(2);
        this.setState({
            price: formatedPriceNew
        });

        await this.props.getAllCatagoryData(paramsData);

        await this.props.getCat(token);

        await this.setState({
            allMemeDetails: this.props.getAllMemeAction,
            getCat: this.props.getCatAction,
            categoryIdMain: categoryVal,
            loader: false
        });
        await this.setState({
            // catDetails: this.props.getAllMemeAction && this.props.getAllMemeAction.allMemeDetails && this.props.getAllMemeAction.allMemeDetails.data && this.props.getAllMemeAction.allMemeDetails.data.catImages,
            getCatList: this.props.getCatAction.getCatData.data.categoryList
        });

        let paramData = {
            token: token
        };

        await this.props.totalMemeCount(paramData);
        await this.setState({
            totalMemeCount: this.props.totalMemeCountAction.totalMemeCount.data.count
        });
        this.refreshStyle();
        await this.setState({
            loader: false
        });

    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (
            prevProps.getAllMemeAction.allMemeDetails !==
            this.props.getAllMemeAction.allMemeDetails &&
            this.props.getAllMemeAction.allMemeDetails.success
        ) {
            this.setState({
                catDetails: this.props.getAllMemeAction.allMemeDetails.data.catImages,
                memeCount: this.props.getAllMemeAction.allMemeDetails.data.memeListCount
            });
        }
        if (prevState.catDetails !== this.state.catDetails) {
            setTimeout(async () => {
                await this.setState({
                    renderScript: true
                });
            }, (this.props.getAllMemeAction.allMemeDetails.data.catImages.length || 1) * 300);
        }
        if (prevState.renderScript !== this.state.renderScript) {
            setTimeout(async () => {
                await this.setState({
                    loader: false
                });
            }, 2000);
        }
    };

    _onChange = async e => {
        let file = e.target.files[0];
        if (file === undefined) {
        } else {
            if (file.type.substring(0, 5) === "image") {
                if (file.type.substring(6, 10) === "webp") {
                    await this.setState({
                        Errimg: "Please upload a vaild image"
                        // errorStatus:true
                    });

                    alert(this.state.Errimg);
                } else {
                    this.setState({
                        Errimg: ""
                    });
                }
            } else {
                await this.setState({
                    Errimg: "Please upload a vaild image"
                    // errorStatus: true
                });
                alert(this.state.Errimg);
            }
            await this.setState({ imgSrc: file });
            // Assuming only image
            var file1 = this.refs.file.files[0];
            if (file1 === undefined) {
            } else {
                var reader = new FileReader();
                var url = reader.readAsDataURL(file1);

                reader.onloadend = function (e) {
                    this.setState({
                        imgSrcBase64: [reader.result]
                    });
                }.bind(this);
            }
        }
    };

    endEditing = () => {
        let formattedPrice = parseFloat(this.state.price);
        let num = formattedPrice.toFixed(2);
        let formattedPriceString = num.toString();
        this.setState({
            price: num
        });
    };
    toogleClick = async toggleState => {
        await this.props.updateToogle(toggleState);
        await this.setState({ toggle: !this.state.toggle });
    };

    selectCategory = async e => {
        let value = e.target.value;
        await this.setState({
            categoryId: value,
            categoryIdErrorMsg: "",
            showModalStyle: true
        });
    };

    selectCategoryFetching = async e => {
        localStorage.setItem("categoryVal", e.target.value);
        await this.setState({
            limit: 1000,
            offset: 0
        });
        this.fetchMoreData();
    };

    refreshStyle = () => {
        let el = findDOMNode(this.refs.wookmark);
        $(el).click();
    };

    fetchMoreData = async () => {
        let token = localStorage.getItem("userToken");
        const categoryVal = localStorage.getItem("categoryVal");

        let paramsData = {
            token: token,
            category: categoryVal,
            limit: this.state.limit + 5,
            offset: 0
        };

        await this.setState({
            limit: this.state.limit + 5
        });

        await this.props.getAllCatagoryData(paramsData);
        this.refreshStyle();
    };

    setPrice = async e => {
        let value = e.target.value;
        await this.setState({ price: value });
        if (!value) {
            this.setState({
                errPrice: "Field is Required."
            });
        } else {
            this.setState({
                errPrice: ""
            });
        }
    };

    uploadData = async () => {
        let error = "";
        let priceVaild = /^\d+(?:[.,]\d+)*$/;
        this.setState({ showModalStyle: true });
        // console.log("this.state.imgSrc", this.state.imgSrc);
        if (!this.state.imgSrc) {
            await this.setState({
                tempimg: true,
                Errimg: ""
            });
        } else if (!(this.state.imgSrc.type.substring(0, 5) === "image")) {
            error = true;
            await this.setState({
                Errimg: "Please upload a vaild image"
                // errorStatus:true
            });
            alert(this.state.Errimg);
        } else if (this.state.imgSrc.type.substring(6, 10) === "webp") {
            error = true;
            await this.setState({
                Errimg: "Please upload a vaild image"
                // errorStatus:true
            });
            alert(this.state.Errimg);
        }
        if (this.state.Errimg) {
        } else if (!this.state.price) {
            this.setState({
                errPrice: "Field is Required."
            });
            error = true;
            this.onClickModal();
        } else if (!priceVaild.test(this.state.price)) {
            this.setState({
                errPrice: "Please enter a valid price"
            });
            this.onClickModal();
        } else if (this.state.categoryId === "") {
            await this.setState({
                categoryIdErrorMsg: "Please Select category"
            });
            this.onClickModal();
        } else if (this.state.categoryId === "no") {
            await this.setState({
                categoryIdErrorMsg: "Please Select category"
            });
            this.onClickModal();
        } else {
            const formatter = new Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2
            });
            let priceNew = formatter.format(this.state.price);
            await this.setState({
                showModal: false,
                loader: true,
                price: priceNew
            });
            let uploadDetails = new FormData();
            uploadDetails.append("file", this.state.imgSrc);
            setTimeout(() => {
                localStorage.removeItem("memeDetails");
                localStorage.removeItem("isReload");
                history.push({
                    pathname: "/createMeme",
                    state: {
                        id: this.state.categoryId,
                        price: this.state.price,
                        imgPath: this.state.imgSrcBase64,
                        imgProperty: [],
                        imageFile: this.state.imgSrc,
                        updating: "upload"
                    }
                });
            }, 200);
        }
    };

    editCard = async (id, imgPath, imgProperty, price) => {
        let params = {
            id: id,
            imgPath: imgPath,
            imgProperty: JSON.parse(imgProperty),
            price: price,
            updating: "edit"
        };
        localStorage.setItem("memeDetails", JSON.stringify(params));
        window.location.href = "/createMeme";
    };

    refresh = () => {
        window.location.href = "/memes";
    };

    onClickModal = async () => {
        await this.setState({
            showModal: true
        });
    };

    deleteMeme = async catName => {
        let paramsData = {
            _id: this.state.DeleteMemeId
        };
        await this.props.deleteMeme(paramsData);

        await this.setState({
            Delete: false
        });
        window.location.reload(true);
    };

    handleClose = e => {
        this.setState({
            Show: false,
            Delete: false
        });
    };

    closeModal = async () => {
        await this.setState({
            errPrice: "",
            Errimg: "",
            categoryIdErrorMsg: ""
        });
    };

    logOut = () => {
        localStorage.removeItem("userToken");
        history.replace("/");
    };

    render() {
        return (
            <>
                {this.state.loader && <Loader />}

                <div className="dash-body" ref={this.myRef}>
                    <div className="header">
                        <div className="header-left">
                            <button
                                id="sidebar-toggle"
                                className="sidebar-toggle"
                                type="button"
                                data-toggle="collapse"
                                aria-controls="navigation-index"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={() => {
                                    this.toogleClick(this.state.toggle);
                                }}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                                <span className="navbar-toggler-icon icon-bar"></span>
                            </button>
                            <div className="title-con">
                                <h1 className="page-title">Invitation</h1>
                            </div>
                        </div>
                        <div id="logout" className="header-right">
                            <div className="user-con">
                                <a
                                    className="link-logout"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.logOut()}
                                >
                                    <i className="material-icons"> power_settings_new</i>
                                    Log Out
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="dashboard-wrapper">
                            <div className="content-header">
                                <select
                                    className="form-control meme-category"
                                    id="meme-category-selector"
                                    name="meme-category"
                                    onChange={e => this.selectCategoryFetching(e)}
                                >
                                    <option value="0" selected={this.state.categoryIdMain === "0"}>
                                        ALL INVITATIONS
                                    </option>
                                    {this.state.getCatList.map((item, index) => {
                                        return (
                                            <option
                                                key={index}
                                                selected={this.state.categoryIdMain === item._id}
                                                value={item._id}
                                            >
                                                {item.name.toUpperCase()}
                                            </option>
                                        );
                                    })}
                                </select>
                                <button
                                    onClick={() => this.onClickModal()}
                                    type="button"
                                    className="btn btn-primary ml-auto"
                                    data-toggle="modal"
                                    data-target="#new-meme-modal"
                                >
                                    New Invitation
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <InfiniteScroll
                                            dataLength={this.state.limit} //This is important field to render the next data
                                            next={this.fetchMoreData}
                                            hasMore={
                                                this.state.limit >= this.state.memeCount
                                                    ? false
                                                    : true
                                            }
                                            loader={
                                                <h4 className="text-center mt-5 mb-5">
                                                    Loading...
                                                </h4>
                                            }
                                            endMessage={
                                                <p className="text-center mt-5">
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                            }
                                        >
                                            <ul
                                                className="tiles-wrap animated"
                                                ref="wookmark"
                                                id="wookmark1"
                                                onLoad={this.refreshStyle}
                                            >
                                                {this.state.catDetails.length ? (
                                                    this.state.catDetails.map((item, index) => {
                                                        return (
                                                            <li>
                                                                <img
                                                                    src={
                                                                        `${apiLink.baseUrl}` +
                                                                        item.memePath[0].thumb
                                                                    }
                                                                    width="100%"
                                                                    height="auto"
                                                                    alt=""
                                                                />
                                                                <div className="tile-details">
                                                                    <p>
                                                                        {item.categoryId.name.toUpperCase()}
                                                                    </p>
                                                                    <p>${item.price}</p>
                                                                    <a
                                                                        href
                                                                        onClick={() => {
                                                                            this.editCard(
                                                                                item._id,
                                                                                `${apiLink.baseUrl}` +
                                                                                item.path[0]
                                                                                    .original,
                                                                                item.imgProperty,
                                                                                item.price
                                                                            );
                                                                        }}
                                                                        className="edit-btn"
                                                                    >
                                                                        <i className="material-icons">
                                                                            edit
                                                                        </i>
                                                                    </a>
                                                                    <a
                                                                        href
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                Delete: true,
                                                                                DeleteMemeId:
                                                                                    item._id
                                                                            })
                                                                        }
                                                                        className="delete-btn"
                                                                    >
                                                                        <i className="material-icons">
                                                                            delete
                                                                        </i>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                ) : (
                                                        <p>No Invitations</p>
                                                    )}
                                            </ul>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showModal === true ? (
                    <div className="modal " id="new-meme-modal">
                        <div className="modal-dialog modal-upload-image">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Upload Image</h4>
                                    <button
                                        type="button"
                                        // onClick={this.refresh}
                                        onClick={this.closeModal}
                                        // className="close"
                                        class="close img-upload-clear"
                                        data-dismiss="modal"
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body modal-form">
                                    <label
                                        style={{ cursor: "pointer" }}
                                        className="upload-image-con"
                                        for="upload-image"
                                    >
                                        {/* <div className="upload-image-con"> */}

                                        <div className="upload-image-inner">
                                            <div>
                                                <label
                                                    for="upload-image"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <div className="image-icon">
                                                        <i className="material-icons">
                                                            add_photo_alternate
                                                        </i>
                                                    </div>
                                                    <strong>Click here</strong> to{" "}
                                                    <span className="text-before">
                                                        upload image
                                                    </span>{" "}
                                                    <span className="text-after">change image</span>
                                                </label>
                                                <input
                                                    ref="file"
                                                    type="file"
                                                    name=""
                                                    id="upload-image"
                                                    className="box__file"
                                                    data-multiple-caption=""
                                                    onChange={e => {
                                                        this._onChange(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="uploaded_image_con">
                                            <div className="image-for-desktop">
                                                <div className="uploaded_image"></div>
                                                <p>Desktop view</p>
                                            </div>
                                            <div className="image-for-mobile">
                                                <div className="uploaded_image"></div>
                                                <p>Mobile view</p>
                                            </div>
                                        </div>

                                        {/* </div> */}
                                    </label>
                                    {/* <span style={{ color: "red" }}>
                        {this.state.Errimg}
                      </span>{" "} */}

                                    <div className="image-category-con">
                                        <div className="form-group">
                                            <label for="email">Enter price</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">$</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={
                                                        this.state.price === "NaN"
                                                            ? 0.0
                                                            : this.state.price
                                                    }
                                                    onChange={e => {
                                                        this.setPrice(e);
                                                    }}
                                                    onBlur={() => this.endEditing()}
                                                />
                                            </div>
                                            <span style={{ color: "red" }}>
                                                {this.state.errPrice}
                                            </span>{" "}
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Select a category</label>
                                            <select
                                                className="form-control"
                                                id=""
                                                name=""
                                                onChange={e => this.selectCategory(e)}
                                            >
                                                <option value="no">Select a category</option>
                                                {this.state.getCatList.map((item, index) => {
                                                    return (
                                                        <option value={item._id}>
                                                            {item.name.toUpperCase()}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <span style={{ color: "red" }}>
                                                {this.state.categoryIdErrorMsg}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-default img-upload-clear"
                                        data-dismiss="modal"
                                        aria-hidden="true"
                                        // onClick={this.refresh}
                                        onClick={this.closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => {
                                            this.uploadData();
                                        }}
                                        type="button"
                                        data-dismiss={
                                            this.state.showModalStyle === false ? "modal" : ""
                                        }
                                        className="btn btn-primary"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                {this.state.renderScript && (
                    <ScriptTag isHydrating={true} type="text/javascript" src="jsScript.js" />
                )}

                <Modal show={this.state.Delete} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Invitation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure to delete this Invitation?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={this.deleteMeme}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default connect(mapStateToProps, {
    ...memeMainAction,
    ...createMemeAction,
    ...dashboardAction,
    ...settingsAction
})(memes);
