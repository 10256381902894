import constants from '../../constants/constants'
const {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
} = constants;

const initialState = {

    loginDetails: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loginDetails: { success: true, ...action.loginAction }
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                loginDetails: { success: false, ...action.error }
            };
        default:
            return state;
    }
}