import React, { Component } from 'react'
import { connect } from 'react-redux'
import settingsAction from '../../actions/settingsAction';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import memeMainAction from '../../actions/memeMainAction';
import Loader from './Loader';
import history from '../config/history';
import createMemeAction from '../../actions/createMemeAction';

const mapStateToProps = (state) => (console.log('SettingsChange', state), {

    changePasswordAction: state.settings,
    setDefaultPriceAction: state.settings,
    getDefaultPriceAction: state.settings,
    uploadFontAction: state.settings,
    deleteFontAction: state.settings,
    fetchFontAction: state.createMeme

})

class settings extends Component {


    constructor(props) {
        super(props);
        this.state = {
            price: "",
            // currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            currentPasswordErrorMsg: '',
            newPasswordErrorMsg: '',
            confirmPasswordErrorMsg: '',
            changePasswordStatus: false,
            Errprice: "",
            updateprice: false,
            toggle: true,
            Errimg: "",
            fontName: "",
            fontNameErr: "",
            fontFile: {},
            fontMessage: "",
            loader: false,
            fontList: [],
            deleteFontId:"",
            fontRemoved:false,
            fontDeleteMessage:"",
            ErrDeleteFont:"",
            fontadded:false
        }
    }

    componentDidMount = async () => {
        await this.setState({ loader: true })
        let token = localStorage.getItem("userToken");
        let paramsData = {
            token: token
        };
        await this.props.getDefaultPrice(paramsData);
        await this.props.fetchFonts()
        // let defaultPrice = localStorage.getItem("defaultPrice")
        await this.setState({
            fontList: this.props.fetchFontAction && this.props.fetchFontAction.fetchFontDetails &&
                this.props.fetchFontAction.fetchFontDetails.data
                && this.props.fetchFontAction.fetchFontDetails.data.list
        })

        // console.log('this.props.getDefaultPriceAction', this.props.getDefaultPriceAction.getDefaultpericeDetails.data.defaultAmount)
        let formatedPrice = parseFloat(this.props.getDefaultPriceAction.getDefaultpericeDetails.data.defaultAmount)
        let formatedPriceNew = formatedPrice.toFixed(2);
        await this.setState({
            price: formatedPriceNew,
            loader: false
        })

    }


    handleChange = async (e) => {
        this.setState({ price: e.target.value })
        if (!e.target.value) {
            this.setState({
                Errprice: "Field is Required."
            });

        } else {
            this.setState({
                Errprice: ""
            });

        }

    }
    changePassword = async () => {

        let passExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;

        // if (this.state.currentPassword === "") {
        //     this.setState({ currentPasswordErrorMsg: "Please Enter Current Password" })
        // } else 
        if (this.state.newPassword === "") {
            this.setState({ newPasswordErrorMsg: "Please Enter New Password" })
        } else if (!passExp.test(this.state.newPassword)) {
            this.setState({ newPasswordErrorMsg: "Must contain 8 characters, one lowercase letter, one uppercase letter, one numeric digit, and one special character" })
        } else if (this.state.confirmPassword === "") {
            this.setState({ confirmPasswordErrorMsg: "Please Retype New Password" })
        } else if (this.state.newPassword !== '' && this.state.newPassword !== this.state.confirmPassword) {
            this.setState({ confirmPasswordErrorMsg: "Password Mismatch" })
        } else {

            let token = localStorage.getItem("userToken")
            let paramsData = {
                token: token,
                // oldpassword: this.state.currentPassword,
                password: this.state.confirmPassword
            }
            await this.props.changePassword(paramsData)
            this.setState({ changePasswordStatus: this.props.setDefaultPriceAction.changePasswordDetails.success })
            if (this.state.changePasswordStatus == true) {
                this.setState({
                    // currentPassword: "",
                    confirmPassword: "",
                    newPassword: ""
                })
            }
        }

    }

    filedChange = (e) => {
        // console.log('e', e)
        // if (e.target.name === "currentPassword") {
        //     this.setState({ currentPassword: e.target.value, currentPasswordErrorMsg: '' })
        // } else
        if (e.target.name === "newPassword") {
            this.setState({ newPassword: e.target.value, newPasswordErrorMsg: '' })
        } else if (e.target.name === "confirmPassword") {
            this.setState({ confirmPassword: e.target.value, confirmPasswordErrorMsg: '' })
        }

    }

    UpdatePrice = async () => {
        const formatter = new Intl.NumberFormat('en-US', {
            // style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        })
        //   let c= formatter.format(1000)
        //   console.log('formatterc', c)

        let error = ""
        let priceVaild = /^\d+(?:[.,]\d+)*$/
        if (!this.state.price) {
            this.setState({
                Errprice: "Field is Required."
            });
            error = true

        } else if (!priceVaild.test(this.state.price)) {
            this.setState({
                Errprice: "Please enter a valid price"
            });
            error = true
        } else {
            if (!error) {
                let formatedPrice = parseFloat(this.state.price)
                let formatedPriceNew = formatedPrice.toFixed(2);
                // localStorage.setItem("defaultPrice", formatedPriceNew)
                // let defaultPrice = localStorage.getItem("defaultPrice")
                // console.log('defaultPrice', defaultPrice)

                let token = localStorage.getItem("userToken")
                let paramsData = {
                    token: token,
                    // oldpassword: this.state.currentPassword,
                    amount: formatedPriceNew
                }
                await this.props.setDefaultPrice(paramsData)

                this.setState({
                    price: formatedPriceNew,
                    updateprice: this.props.changePasswordAction.setDefaultpericeDetails.success
                })


                // alert('Default Price Updated')
            }
        }



    }
    handleClose = () => {
        this.setState({
            updateprice: false
        });
    };

    _onChange = async e => {
        let file = e.target.files[0];
        // console.log('file.type', file.type)
        if (file !== undefined) {

            if (file.type.substring(0, 5) === "image") {
                await this.setState({
                    Errimg: "Please upload a vaild font file"
                });
                alert(this.state.Errimg)
            } else if (file.type.substring(0, 4) === "text") {
                await this.setState({
                    Errimg: "Please upload a vaild font file"
                });
                alert(this.state.Errimg)
            }
            else if (file.type.substring(0, 11) === "application") {
                await this.setState({
                    Errimg: "Please upload a vaild font file"
                });
                alert(this.state.Errimg)
            }
            else {
                console.log('filetype', typeof (file))
                await this.setState({
                    Errimg: "",
                    fontFile: file
                });
                console.log('this.state.fontFile', this.state.fontFile)

            }


        }


        // await this.setState({ imgSrc: file });

        // console.log("this.refs.file.files[0]", file);
        // Assuming only image
        // var file1 = this.refs.file.files[0];
        // if (file1 === undefined) {
        // } else {
        //   var reader = new FileReader();
        //   var url = reader.readAsDataURL(file1);

        //   reader.onloadend = function(e) {
        //     this.setState({
        //       imgSrcBase64: [reader.result]
        //     });
        //   }.bind(this);
        // }
    };

    fontName = (e) => {
        this.setState({
            fontName: e.target.value,
            fontNameErr: ''
        })
    }


    addFont = async () => {
        let file = document.getElementById("fileName");
        var fileName = file.value
        console.log('fileName', fileName)
        //   if(Object.keys(this.state.fontFile).length === 0){
        if (fileName === '') {
            this.setState({
                Errimg: "Please upload font file"
            })
        } else if (this.state.fontName === "") {
            this.setState({
                fontNameErr: "Please enter font name"
            })
        } else {
            await this.setState({ loader: true })

            let fontDetails = new FormData()
            await fontDetails.append("font", this.state.fontFile)
            await fontDetails.append("data", JSON.stringify({ name: this.state.fontName.toLowerCase() }))

            await this.props.uploadFont(fontDetails)

            await this.setState({
                fontMessage: this.props.uploadFontAction && this.props.uploadFontAction.fontUploadDetails && this.props.uploadFontAction.fontUploadDetails.message
            })
            console.log('fontMessage', this.state.fontMessage)

            if (this.state.fontMessage === " Successfully created") {
                // alert('Font Uploaded')
                // window.location.reload()
                await this.setState({
                    // fontFile:{},
                    // fontName:''
                    loader: false,
                    fontadded:true
                })
            } else {
                alert('Font Uploading Failed')
            }

        }

    }
    changeFont = (e)=>{
        console.log('e.target.value', e.target.value)
        this.setState({
            deleteFontId: e.target.value,
            ErrDeleteFont:''
        })

    }

    removeFont = async () => {
        if(this.state.deleteFontId === ""){
            this.setState({ ErrDeleteFont: "Please select font" })
        }else{

            console.log('this.state.deleteFontId', this.state.deleteFontId)
            let params={
                _id :this.state.deleteFontId
            }
            await this.props.deleteFont(params)
    
            console.log('this.props.deleteFontAction.fontDeleteDetails', this.props.deleteFontAction.fontDeleteDetails)
     
            await this.setState({
                fontDeleteMessage: this.props.deleteFontAction &&this.props.deleteFontAction.fontDeleteDetails &&this.props.deleteFontAction.fontDeleteDetails.message
            })
            console.log('fontDeleteMessage', this.state.fontDeleteMessage)
            if(this.state.fontDeleteMessage === " Successfully updated"){
                this.setState({ fontRemoved : true })
             
                // alert('Font Deleted')
            }else{
                alert('Font is not deleted. Please try again')
            }
        }
    }

    okPressed =()=>{
        this.setState({fontRemoved : false})
        window.location.reload()

    }

    okPressedfontAdded = ()=>{
        this.setState({fontadded : false})
        window.location.reload()
    }


    toogleClick = async (toggleState) => {
        await this.props.updateToogle(toggleState)
        await this.setState({ toggle: !this.state.toggle })
    }

    logOut = () => {

        localStorage.removeItem('userToken');
        history.replace('/')

    }

    render() {
        return (
            <>
                {this.state.loader && <Loader />}
                <div class="dash-body">
                    <div class="header">
                        <div class="header-left">
                            <button onClick={() => { this.toogleClick(this.state.toggle) }} id="sidebar-toggle" class="sidebar-toggle" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="navbar-toggler-icon icon-bar"></span>
                                <span class="navbar-toggler-icon icon-bar"></span>
                                <span class="navbar-toggler-icon icon-bar"></span>
                            </button>
                            <div class="title-con">
                                <h1 class="page-title">
                                    Settings
                            </h1>
                            </div>
                        </div>
                        <div class="header-right">
                            <div class="user-con">
                                <a class="link-logout" style={{ cursor: "pointer" }} onClick={() => this.logOut()}>
                                    <i class="material-icons"> power_settings_new</i>
                                Log Out
                             </a>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="dashboard-wrapper">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card card-settings">
                                        <h4 class="settings-title">
                                            Default price
                                     </h4>
                                        <div class="set-price-group">
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">$</span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Enter price" value={this.state.price} onChange={(e) => { this.handleChange(e) }} />

                                                </div>
                                                <span style={{ color: "red" }} >{this.state.Errprice}</span>
                                            </div>
                                            <button onClick={() => this.UpdatePrice()} class="btn btn-secondary">Update</button>
                                        </div>
                                        <h4 class="settings-title">
                                            Change password
                                        </h4>
                                        <div class="set-password-group">
                                            {/* <div class="form-group">
                                            <label for="pwd-cur">Current Password</label>
                                            <input type="password" class="form-control" placeholder="Enter password" id="pwd-cur" name="currentPassword" value={this.state.currentPassword} onChange={(e) => { this.filedChange(e) }} />
                                            <span style={{ color: "red" }}>{this.state.currentPasswordErrorMsg}</span>
                                        </div> */}
                                            <div class="form-group">
                                                <label for="pwd-new">New Password</label>
                                                <input type="password" class="form-control" placeholder="Enter password" id="pwd-new" name="newPassword" value={this.state.newPassword} onChange={(e) => { this.filedChange(e) }} />
                                                <span style={{ color: "red" }}>{this.state.newPasswordErrorMsg}</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="pwd-ret-new">Confirm Password</label>
                                                <input type="password" class="form-control" placeholder="Enter password" id="pwd-ret-new" name="confirmPassword" value={this.state.confirmPassword} onChange={(e) => { this.filedChange(e) }} />
                                                <span style={{ color: "red" }}>{this.state.confirmPasswordErrorMsg}</span>
                                            </div>
                                            <div class="form-group">
                                                <div class="d-flex">
                                                    <button onClick={() => { this.changePassword() }} type="submit" class="btn btn-success ml-auto">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 class="settings-title">
                                            Add Font
                    </h4>
                                        <div class="set-password-group add-font-group">

                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input type="file" class="form-control input-add-font" id="fileName" placeholder="" onChange={e => { this._onChange(e) }} />
                                                </div>
                                                <span style={{ color: 'red' }} >{this.state.Errimg}</span>
                                            </div>
                                            <div class="form-group">
                                                <label for="pwd-ret-new">Enter Font Name</label>
                                                <input type="Text" class="form-control" placeholder="Font name" value={this.state.fontName} onChange={(e) => { this.fontName(e) }} />
                                                <span style={{ color: 'red' }} >{this.state.fontNameErr}</span>
                                            </div>
                                            <div class="form-group">
                                                <div class="d-flex">
                                                    <button onClick={() => { this.addFont() }} class="btn ml-auto btn-primary">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <h4 class="settings-title">
                      Remove Font
                    </h4>
                    <div class="set-price-group">
                      <div class="form-group">
                        <div class="input-group">
                           <select name="" class="form-control" onChange={(e)=>{this.changeFont(e)}}>
                           <option value="">Select Font</option>
                           {
                                                this.state.fontList && this.state.fontList.map((item, index) => {
                                                    console.log('item.original', item)
                                                    return (
                            // <option value="">option</option>

                                                        <option value={item._id} >{item.name}</option>
                                                    )
                                                })
                                            }


                            {/* <option value="">option</option>
                            <option value="">option</option>
                            <option value="">option</option>
                            <option value="">option</option> */}
                          </select>
                        </div>
                        <span style={{ color:'red' }}>{this.state.ErrDeleteFont}</span>
                      </div>
                      <button class="btn btn-secondary btn-secondary-danger" onClick={()=>this.removeFont()}>Remove</button>
                    </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.updateprice} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Price Updated</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Default Price Updated</Modal.Body>
                        <Modal.Footer>

                            <Button variant="primary" onClick={this.handleClose}>
                                ok
            </Button>
                        </Modal.Footer>
                    </Modal>


                    <Modal show={this.state.fontRemoved}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Font deleted successfully</Modal.Body>
                        <Modal.Footer>

                            <Button variant="primary" onClick={()=>this.okPressed()}>
                                Ok
            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.fontadded}>
                        <Modal.Header closeButton>
                            <Modal.Title>Alert</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Font added successfully</Modal.Body>
                        <Modal.Footer>

                            <Button variant="primary" onClick={()=>this.okPressedfontAdded()}>
                                Ok
            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>


            </>
        )
    }
}

export default connect(mapStateToProps, { ...settingsAction, ...memeMainAction, ...createMemeAction })(settings);
