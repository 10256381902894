import constants from '../../constants/constants'
const {
    GET_CATEGORY_LIST_REQUEST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAILURE,
    ADD_CATEGORY_REQUEST,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAILURE,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAILURE
} = constants;

const initialState = {

    categoryList: {},
    addCategoryDetails: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_LIST_REQUEST:
            return {
                ...state
            };
        case GET_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categoryList: { success: true, ...action.getCategoryListAction }
            };
        case GET_CATEGORY_LIST_FAILURE:
            return {
                ...state,
                categoryList: { success: false, ...action.error }
            };

        case ADD_CATEGORY_REQUEST:
            return {
                ...state
            };
        case ADD_CATEGORY_SUCCESS:
            return {
                ...state,
                addCategoryDetails: { success: true, ...action.addCategoryAction }
            };
        case ADD_CATEGORY_FAILURE:
            return {
                ...state,
                addCategoryDetails: { success: false, ...action.error }
            };
        case DELETE_CATEGORY_REQUEST:
            return {
                ...state
            };
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                deleteCategoryDetails: { success: true, ...action.deleteCategoryAction }
            };
        case DELETE_CATEGORY_FAILURE:
            return {
                ...state,
                deleteCategoryDetails: { success: false, ...action.error }
            };

        default:
            return state;
    }
}