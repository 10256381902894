import apiLink from "../../api/apiLink"
import memeGateWay,{ namuAuthenticator } from "../config/service";

const publishData = params =>
    memeGateWay.put(apiLink.publishData,params) ;

    const fetchFonts = params =>
    memeGateWay.get(apiLink.fetchFonts) ;

export default {
    publishData,
    fetchFonts
};