import constants from '../../constants/constants'
const {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    SET_DEFAULT_PRICE_REQUEST,
    SET_DEFAULT_PRICE_SUCCESS,
    SET_DEFAULT_PRICE_FAILURE,
    GET_DEFAULT_PRICE_REQUEST,
    GET_DEFAULT_PRICE_SUCCESS,
    GET_DEFAULT_PRICE_FAILURE,
    UPLOAD_FONT_REQUEST,
UPLOAD_FONT_SUCCESS,
UPLOAD_FONT_FAILURE,
DELETE_FONT_REQUEST,
DELETE_FONT_SUCCESS,
DELETE_FONT_FAILURE,
} = constants;

const initialState = {

    changePasswordDetails: {},
    setDefaultpericeDetails:{},
    getDefaultpericeDetails:{},
    fontUploadDetails:{},
    fontDeleteDetails:{}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordDetails: { success: true, ...action.changePasswordAction }
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordDetails: { success: false, ...action.error }
        };
        case SET_DEFAULT_PRICE_REQUEST:
            return {
                ...state
            };
        case SET_DEFAULT_PRICE_SUCCESS:
            return {
                ...state,
                setDefaultpericeDetails: { success: true, ...action.setDefaultPriceAction }
            };
        case SET_DEFAULT_PRICE_FAILURE:
            return {
                ...state,
                setDefaultpericeDetails: { success: false, ...action.error }
            };   
        case GET_DEFAULT_PRICE_REQUEST:
            return {
                ...state
            };
        case GET_DEFAULT_PRICE_SUCCESS:
            return {
                ...state,
                getDefaultpericeDetails: { success: true, ...action.getDefaultPriceAction }
            };
        case GET_DEFAULT_PRICE_FAILURE:
            return {
                ...state,
                getDefaultpericeDetails: { success: false, ...action.error }
            }; 
            
            case UPLOAD_FONT_REQUEST:
                return {
                    ...state
                };
            case UPLOAD_FONT_SUCCESS:
                return {
                    ...state,
                    fontUploadDetails: { success: true, ...action.uploadFontAction }
                };
            case UPLOAD_FONT_FAILURE:
                return {
                    ...state,
                    fontUploadDetails: { success: false, ...action.error }
                }; 
            
                case DELETE_FONT_REQUEST:
                    return {
                        ...state
                    };
                case DELETE_FONT_SUCCESS:
                    return {
                        ...state,
                        fontDeleteDetails: { success: true, ...action.deleteFontAction }
                    };
                case DELETE_FONT_FAILURE:
                    return {
                        ...state,
                        fontDeleteDetails: { success: false, ...action.error }
                    }; 
                

        default:
            return state;
    }
}