import React from 'react';

export default function RenderColor(props) {
    let colors = ["#FFFFFF", "#000000", "#EEEEEE", "#BDBDBD", "#757575", "#B0BEC5", "#78909C", "#546E7A", "#EF9A9A",
        "#EF5350", "#E53935", "#FF1744", "#F48FB1", "#AB47BC", "#8E24AA", "#D500F9", "#7E57C2",
        "#5E35B1", "#651FFF", "#5C6BC0", "#3D5AFE", "#2979FF", "#66BB6A", "#29B6F6", "#43A047",
        "#00E676", "#FFEA00", "#FF9100", "#FF7043", "#FF3D00", "#8D6E63", "#6D4C41", "#FFD700"];
    return (
        <div class="color-palette-items">
            {
                colors.map((color, idx) => (
                    <label class="color-item" for={`color${idx+1}`}>
                        <input
                            class="color-input"
                            id={`color${idx+1}`}
                            type="radio"
                            name="textColor"
                            value={color}
                            onChange={(e) => props.handleColorChange(e)}
                        />
                        <span
                            class="color-item-color"
                            style={{
                                backgroundColor: color
                            }}
                        ></span>
                    </label>
                ))
            }
        </div>
    )
}
