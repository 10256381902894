import apiLink from "../../api/apiLink"
import memeGateWay, { namuAuthenticator } from "../config/service";

const uploadImage = params =>
    memeGateWay.post(apiLink.uploadImage, params);
const uploadImageUpdate = params =>
    memeGateWay.put(apiLink.uploadImage, params);
export default {
    uploadImage,
    uploadImageUpdate
};


// import apiLink from "../../api/apiLink"
// import apiCall from "../../api/apiCall"
// const headerText = { 'Access-Control-Allow-Origin': '*' }

// const uploadImage = async (params) => {
//     console.log('loginData', params)

//     const paramsData = {
//         apiLink: apiLink.uploadImage,
//         method: 'post',
//         headers: {
//             Authorization: `Bearer ${params.token}`,
//             headerText, 
//         },
//         data: {
//             categoryId:params.categoryId, 
//             imgProperty:params.imgProperty,
//             price:params.price
//           }
//     }
//     console.log('paramsData', paramsData)
//     const uploadImageData = await apiCall.postApiCallNew(paramsData)
//     console.log('uploadImageData', uploadImageData)
//     return uploadImageData;
// }
// export default {
//     uploadImage,
// };