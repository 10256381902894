import React, { Component } from 'react';
import Draggable from 'react-draggable';
import ContentEditable from 'react-contenteditable';
import ReactResizeDetector from 'react-resize-detector';

export default class EditableElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: {
                y: 0,
                x: 0
            },
            textWidth: 0,
            textHeight: 0,
            scaledfontSize: 0
        };
        this.editableText = React.createRef();
    }
    async componentDidMount() {
        // await this.setTextDim();
        await this.getTopnLeft();
    }
    async componentDidUpdate(prevProps, prevState) {
        // await this.setTextDim();
        await this.getFontSize(this.props.text.fontSize);
        await this.getTopnLeft();
    }
    dragEnd = (e, data) => {
        // console.log(e.layerX, e.layerY)
        // console.log(data)
        let topPercent = 0;
        let leftPercent = 0;
        let parentHeight = document.getElementById("editedElement").offsetHeight;
        let parentWidth = document.getElementById("editedElement").offsetWidth;
        topPercent = 100 * parseFloat(data.y / parentHeight);
        leftPercent = 100 * parseFloat(data.x / parentWidth);
        this.setState({
            position: {
                x: data.x,
                y: data.y
            }
        });
        this.props.dragEnd(leftPercent, topPercent);
    }
    handleTextChange = e => {
        let content = e.target.value;
        if (e.nativeEvent.inputType === "insertFromPaste") {
            // let contenta = ReactHtmlParser(e.target.value);
            var dom = document.createElement('div');
            dom.innerHTML = content
            const listItems = dom.children;
            const listArray = Array.from(listItems);
            listArray.forEach((item) => {
                item.removeAttribute('style');
                // console.log(item.children)
                if (item.children.length > 0) {
                    const child = item.children;
                    const childArray = Array.from(child);
                    childArray.forEach((item) => {
                        // console.log(item)
                        item.removeAttribute('style')
                        const child = item.children;
                        const childArray = Array.from(child);
                        childArray.forEach((item) => {
                            item.removeAttribute('style')
                        })
                    })
                }
                // console.log(item.children)
            });
            dom.removeAttribute('style')
            content = dom.innerHTML;
            // console.log(dom.innerHTML)
        }
        this.props.editText(content)
    }
    setTextDim = (width, height) => {
        console.log(width, height)
        this.editableText.current && console.log("Current", this.editableText.current.offsetWidth, "State")
        let { textWidth, textHeight } = this.state;
        if (this.editableText.current) {
            if (textWidth !== width || textHeight !== height)
                this.setState({
                    textHeight: height,
                    textWidth: width
                }, console.log("SetState", this.props.text.value))
        }
    }
    getTopnLeft = async () => {
        // console.log("Gets Top")
        let topPx, leftPx = 0;
        // console.log(this.props.stageWidth, this.props.stageHeight, this.props.text.top, this.props.text.left);
        topPx = (parseFloat(this.props.text.top) * this.props.stageHeight) / 100;
        leftPx = (parseFloat(this.props.text.left) * this.props.stageWidth) / 100;
        let { textHeight, textWidth } = this.state;
        // console.log("TExt height width", textHeight, textWidth);
        if (this.props.text.top === 50 && this.props.text.left !== 50) {
            topPx = topPx - (textHeight / 2);
        }
        if (this.props.text.left === 50 && this.props.text.top !== 50) {
            leftPx = leftPx - (textWidth / 2);
        }
        if (this.props.text.top === 50 && this.props.text.left === 50) {
            topPx = topPx - (textHeight / 2);
            leftPx = leftPx - (textWidth / 2);
        }
        let position = {
            x: leftPx,
            y: topPx
        }

        let oldPosition = this.state.position;
        if (position.x !== oldPosition.x || position.y !== oldPosition.y) {
            console.log("a")
            await this.setState({
                position
            })
        }
    }
    getFontSize = async fontSize => {
        var originalImageWidth = 700;
        var currentWidth = this.props.stageWidth;
        let scaledfontSize = Math.floor((currentWidth * parseInt(fontSize)) / originalImageWidth) - 1;
        if (fontSize) {
            if (this.state.scaledfontSize !== scaledfontSize)
                await this.setState({
                    scaledfontSize
                })
        }
    }
    render() {
        let { selected, index, handleClick } = this.props;
        let { color, value, align, bold, italic, textDecoration, fontSize, fontFamily, lineHeight } = this.props.text;
        let { position, textHeight, textWidth, scaledfontSize } = this.state;
        // console.log(this.position)
        return (
            <>
                <ReactResizeDetector handleHeight handleWidth onResize={this.setTextDim}>
                    <Draggable
                        handle="strong"
                        bounds="parent"
                        // onDrag={this.dragging}
                        position={{ ...position }}
                        defaultPosition={{ ...position }}
                        onStop={this.dragEnd}
                    >
                        <div ref={this.editableText} onClick={(e) => handleClick(e, index)} style={{ position: 'absolute', display: 'inline-block', wordWrap: 'break-word', whiteSpace: 'nowrap', textAlign: align, color: color, fontFamily: fontFamily, fontSize: scaledfontSize, fontStyle: italic ? "italic" : "", fontWeight: bold ? "bold" : "", textDecoration: textDecoration ? "underline" : "", padding: 5, lineHeight: lineHeight, zIndex: selected === index ? 999 : "" }}>
                            <ContentEditable html={value} onChange={this.handleTextChange} style={{ padding: index === selected ? 5 : 6, maxWidth: this.props.stageWidth, border: index === selected ? '1px dashed black' : '' }} />
                            {selected === index && <div className="cursor"><strong>+</strong></div>}
                        </div>
                    </Draggable>
                </ReactResizeDetector>
                {/* {this.setTextDim()} */}
            </>
        )
    }
}
