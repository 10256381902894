import React from "react";
import { Route, Redirect } from "react-router-dom";
import memeMainAction from "../../actions/memeMainAction";
import {connect} from 'react-redux'

// const refreshPage =()=>{
//     // alert(1)
//     window.location.reload(false);
//     console.log('windwoLoaded')
// }
const mapStateToProps = (state) => (console.log('toogleStatus'), {
    toogleAction: state.memeMain.toogleState

})

const PrivateRoute = ({ component: Component, ...rest }) => {
    // console.log('rest', rest)
    return (
        <Route
            {...rest}
            render={props =>
                localStorage.getItem("userToken") ? (
                <main id="wrapper" class={rest.toogleAction.toggle ? "main-container toggled" : "main-container "}>
                    <div class="sidebar">
                        <div class="main-logo">
                            <a style={{cursor:"pointer"}} onClick={()=>{ window.location.href = '/dashboard'}}>
                                <img style={{width:"250px",maxWidth:"100%"}} src="assets/images/logo-login.png" />
                            </a>
                        </div>
                        <nav class="sidebar-nav">
                            <ul>
                                <li class="nav-header">
                                    Menu
                                </li>
                                <li class={rest.location.pathname === '/dashboard' ? "nav-item active" : "nav-item"}>
                                    <a href="/dashboard">
                                        <i class="material-icons">dashboard</i> Dashboard
                                    </a>
                                </li>
                                <li class={rest.location.pathname === '/categories' ? "nav-item active" : "nav-item"}>
                                    <a href="/categories">
                                        <i class="material-icons">playlist_add</i> Categories
                                    </a>
                                </li>
                                <li class={rest.location.pathname === '/memes' ? "nav-item active" : rest.location.pathname === '/createMeme' ? "nav-item active" : "nav-item"}>
                                    <a href="/memes" >
                                        <i class="material-icons">bubble_chart</i> Invitations
                                    </a>
                                </li>
                                <li class={rest.location.pathname === '/settings' ? "nav-item active" : "nav-item"}>
                                    <a href="/settings">
                                        <i class="material-icons">settings</i> Settings
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div class="sidebar-footer">

                        </div>
                    </div>
                    <Component />
                </main>
                )
                :
                (
                    <Redirect to={{ pathname: '/' }} />
                )
            }
        />
    );
};
 
export default connect(mapStateToProps, { ...memeMainAction })(PrivateRoute);
