import constants from '../../constants/constants'
const {
    GET_ALL_DATA_MEME_REQUEST,
    GET_ALL_DATA_MEME_SUCCESS,
    GET_ALL_DATA_MEME_FAILURE,
    GET_CAT_REQUEST,
    GET_CAT_SUCCESS,
    GET_CAT_FAILURE,
    DELETE_MEME_REQUEST,
    DELETE_MEME_SUCCESS,
    DELETE_MEME_FAILURE,
    UPLOAD_IMAGE_REQUEST,
UPLOAD_IMAGE_SUCCESS,
UPLOAD_IMAGE_FAILURE,
TOGGLE_STATE
} = constants;

const initialState = {

    allMemeDetails: {},
    getCatData: {},
    deleteMemeDetails: {},
    uploadImageDetails:{},
    toogleState:{}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DATA_MEME_REQUEST:
            return {
                ...state
            };
        case GET_ALL_DATA_MEME_SUCCESS:
            return {
                ...state,
                allMemeDetails: { success: true, ...action.getAllMemeAction }
            };
        case GET_ALL_DATA_MEME_FAILURE:
            return {
                ...state,
                allMemeDetails: { success: false, ...action.error }
            };

        case GET_CAT_REQUEST:
            return {
                ...state
            };
        case GET_CAT_SUCCESS:
            return {
                ...state,
                getCatData: { success: true, ...action.getCatAction }
            };
        case GET_CAT_FAILURE:
            return {
                ...state,
                getCatData: { success: false, ...action.error }
            };
        case DELETE_MEME_REQUEST:
            return {
                ...state
            };
        case DELETE_MEME_SUCCESS:
            return {
                ...state,
                deleteMemeDetails: { success: true, ...action.deleteMemeAction }
            };
        case DELETE_MEME_FAILURE:
            return {
                ...state,
                deleteMemeDetails: { success: false, ...action.error }
            };

            case UPLOAD_IMAGE_REQUEST:
                return {
                    ...state
                };
            case UPLOAD_IMAGE_SUCCESS:
                return {
                    ...state,
                    uploadImageDetails: { success: true, ...action.uploadImageAction }
                };
            case UPLOAD_IMAGE_FAILURE:
                return {
                    ...state,
                    uploadImageDetails: { success: false, ...action.error }
                };
                case TOGGLE_STATE + "true":
                    return {
                        ...state,
                        toogleState: { toggle: true }
                    }
                case TOGGLE_STATE + "false":
                    return {
                        ...state,
                        toogleState: { toggle: false }
                    }

        default:
            return state;
    }


}