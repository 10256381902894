import apiLink from "../../api/apiLink"
import apiCall from "../../api/apiCall"
import memeGateWay, { namuAuthenticator } from "../config/service";
const headerText = { 'Access-Control-Allow-Origin': '*' }


const changePassword = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        apiLink: apiLink.changePassword,
        method: 'post',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText,
        },
        data: {
            oldpassword: params.oldpassword,
            password: params.password
        }
    }
    const changePasswordData = await apiCall.postApiCallNew(paramsData)
    // console.log('changePasswordData', changePasswordData)
    return changePasswordData;
}
const setDefaultPrice = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        apiLink: apiLink.setDefaultPrice,
        method: 'post',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText,
        },
        data: {
            amount: params.amount
        }
    }
    const setDefaultPriceData = await apiCall.postApiCallNew(paramsData)
    // console.log('setDefaultPriceData', setDefaultPriceData)
    return setDefaultPriceData;
}
const getDefaultPrice = async (params) => {
    // console.log('loginData', params)

    const paramsData = {
        // data:{category: params.category},
        apiLink: apiLink.getDefaultPrice,
        method: 'get',
        headers: {
            Authorization: `Bearer ${params.token}`,
            headerText
        }
    }
    const getDefaultPriceData = await apiCall.getApiCall(paramsData)
    // console.log('getDefaultPriceData', getDefaultPriceData)
    return getDefaultPriceData;
}

const uploadFont = params =>
    memeGateWay.post(apiLink.uploadFont, params);

    const deleteFont = params =>
    memeGateWay.put(apiLink.deleteFont, params);
export default {
    changePassword,
    setDefaultPrice,
    getDefaultPrice,
    uploadFont,
    deleteFont
};
