import axios from 'axios'

const headerText = { 'Access-Control-Allow-Origin': '*' }
const ApiCall = {
  postApiCall,
  getApiCall,
  postApiCallNew
}

async function postApiCall(args) {
  let returnVal = '';
  await axios({
    method: args.method,
    url: args.apiLink,
    // body: args.data,
    headers: headerText,
  })
    .then(response => {
      // console.log('response', response)
      returnVal = response;
    })
    .catch(error => {
      // console.log('errorss', error.response)
      returnVal = error.response;
    });
  return await returnVal;
}

async function getApiCall(args) {
  let returnVal = '';
  await axios({
    method: args.method,
    url: args.apiLink,
    params: args.data,
    headers: args.headers
  })
    .then(response => {
      // console.log('response', response)
      returnVal = response;
    })
    .catch(error => {
      console.log('error', error);
      returnVal = error.response
    })
  return await returnVal;
}
async function postApiCallNew(args) {
  let returnVal = '';
  await axios({
    method: args.method,
    url: args.apiLink,
    data: args.data,
    headers: args.headers
  })
    .then(response => {
      // console.log('response', response)
      returnVal = response;
    })
    .catch(error => {
      console.log('error', error);
      returnVal = error.response
    })
  return await returnVal;
}

export default ApiCall