import constants from '../../constants/constants'
const {
    TOTAL_CATEGORIES_REQUEST,
    TOTAL_CATEGORIES_SUCCESS,
    TOTAL_CATEGORIES_FAILURE,
    TOTAL_MEME_COUNT_REQUEST,
    TOTAL_MEME_COUNT_SUCCESS,
    TOTAL_MEME_COUNT_FAILURE,
    TOTAL_REVENUE_COUNT_REQUEST,
TOTAL_REVENUE_COUNT_SUCCESS,
TOTAL_REVENUE_COUNT_FAILURE,
} = constants;

const initialState = {

    totalCatCount: {},
    totalMemeCount: {},
    totalRevenueCount: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case TOTAL_CATEGORIES_REQUEST:
            return {
                ...state
            };
        case TOTAL_CATEGORIES_SUCCESS:
            return {
                ...state,
                totalCatCount: { success: true, ...action.totalCategoryAction }
            };
        case TOTAL_CATEGORIES_FAILURE:
            return {
                ...state,
                totalCatCount: { success: false, ...action.error }
            };

        case TOTAL_MEME_COUNT_REQUEST:
            return {
                ...state
            };
        case TOTAL_MEME_COUNT_SUCCESS:
            return {
                ...state,
                totalMemeCount: { success: true, ...action.totalMemeCountAction }
            };
        case TOTAL_MEME_COUNT_FAILURE:
            return {
                ...state,
                totalMemeCount: { success: false, ...action.error }
            };

            case TOTAL_REVENUE_COUNT_REQUEST:
                return {
                    ...state
                };
            case TOTAL_REVENUE_COUNT_SUCCESS:
                return {
                    ...state,
                    totalRevenueCount: { success: true, ...action.totalRevenueCountAction }
                };
            case TOTAL_REVENUE_COUNT_FAILURE:
                return {
                    ...state,
                    totalRevenueCount: { success: false, ...action.error }
                };


        default:
            return state;
    }
}