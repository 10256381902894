import constants from '../constants/constants';
import authServices from '../components/services/authServices';
import history from '../components/config/history';
import settingsService from '../components/services/settingsService';

const {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    SET_DEFAULT_PRICE_REQUEST,
    SET_DEFAULT_PRICE_SUCCESS,
    SET_DEFAULT_PRICE_FAILURE,
    GET_DEFAULT_PRICE_REQUEST,
    GET_DEFAULT_PRICE_SUCCESS,
    GET_DEFAULT_PRICE_FAILURE,
    UPLOAD_FONT_REQUEST,
UPLOAD_FONT_SUCCESS,
UPLOAD_FONT_FAILURE,
DELETE_FONT_REQUEST,
DELETE_FONT_SUCCESS,
DELETE_FONT_FAILURE,
} = constants

const changePassword = (params) => {

    const request = changePasswordAction => ({
        type: CHANGE_PASSWORD_REQUEST,
        changePasswordAction
    });
    const success = changePasswordAction => ({
        type: CHANGE_PASSWORD_SUCCESS,
        changePasswordAction
    });
    const failure = error => ({
        type: CHANGE_PASSWORD_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await settingsService.changePassword(params)
            // console.log('res', res)
            if (res.status === 200) {
                const resetPassword = res.data;
                if (resetPassword.success) {
                    localStorage.setItem("userToken", res.data.data.token)
                    dispatch(success(resetPassword));
                    alert('Password has been successfully changed')
                    // history.push('/settings');
                } else {
                    dispatch(failure(resetPassword));
                    alert(resetPassword.message)
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const setDefaultPrice = (params) => {

    const request = setDefaultPriceAction => ({
        type: SET_DEFAULT_PRICE_REQUEST,
        setDefaultPriceAction
    });
    const success = setDefaultPriceAction => ({
        type: SET_DEFAULT_PRICE_SUCCESS,
        setDefaultPriceAction
    });
    const failure = error => ({
        type: SET_DEFAULT_PRICE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await settingsService.setDefaultPrice(params)
            // console.log('res', res)
            if (res.status === 200) {
                const defaultPrice = res.data;
                if (defaultPrice.success) {
                    // localStorage.setItem("userToken", res.data.data.token)
                    dispatch(success(defaultPrice));
                    // alert('Price has been successfully updated')
                    // history.push('/settings');
                } else {
                    dispatch(failure(defaultPrice));
                    alert(defaultPrice.message)
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}
const getDefaultPrice = (params) => {

    const request = getDefaultPriceAction => ({
        type: GET_DEFAULT_PRICE_REQUEST,
        getDefaultPriceAction
    });
    const success = getDefaultPriceAction => ({
        type: GET_DEFAULT_PRICE_SUCCESS,
        getDefaultPriceAction
    });
    const failure = error => ({
        type: GET_DEFAULT_PRICE_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            const res = await settingsService.getDefaultPrice(params)
            // console.log('res', res)
            if (res.status === 200) {
                const defaultPrice = res.data;
                if (defaultPrice.success) {
                    // localStorage.setItem("userToken", res.data.data.token)
                    dispatch(success(defaultPrice));
                    // alert('Price has been successfully updated')
                    // history.push('/settings');
                } else {
                    dispatch(failure(defaultPrice));
                    alert(defaultPrice.message)
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}


const uploadFont = (params) => {

    const request = uploadFontAction => ({
        type: UPLOAD_FONT_REQUEST,
        uploadFontAction
    });
    const success = uploadFontAction => ({
        type: UPLOAD_FONT_SUCCESS,
        uploadFontAction
    });
    const failure = error => ({
        type: UPLOAD_FONT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            // console.log('params', params)
            const res = await settingsService.uploadFont(params)
            console.log('uploadFont', res)
            if (res.status === 200) {
                const fontData = res.data;
                if (fontData.success) {
                    dispatch(success(fontData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(fontData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}


const deleteFont = (params) => {

    const request = deleteFontAction => ({
        type: DELETE_FONT_REQUEST,
        deleteFontAction
    });
    const success = deleteFontAction => ({
        type: DELETE_FONT_SUCCESS,
        deleteFontAction
    });
    const failure = error => ({
        type: DELETE_FONT_FAILURE,
        error
    });

    return async dispatch => {
        try {
            dispatch(request());
            // console.log('params', params)
            const res = await settingsService.deleteFont(params)
            console.log('deleteFont', res)
            if (res.status === 200) {
                const fontData = res.data;
                if (fontData.success) {
                    dispatch(success(fontData));
                    // history.push('/dashboard');
                } else {
                    dispatch(failure(fontData));
                }
            } else {
                dispatch(failure(res.response.data));
            }
        } catch (e) {
            dispatch(failure(e.response && e.response.data));
        }
    }
}

export default {
    changePassword,
    setDefaultPrice,
    getDefaultPrice,
    uploadFont,
    deleteFont

}