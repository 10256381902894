export default {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    GET_ALL_DATA_MEME_REQUEST: "GET_ALL_DATA_MEME_REQUEST",
    GET_ALL_DATA_MEME_SUCCESS: "GET_ALL_DATA_MEME_SUCCESS",
    GET_ALL_DATA_MEME_FAILURE: "GET_ALL_DATA_MEME_FAILURE",

    GET_CATEGORY_LIST_REQUEST: "GET_CATEGORY_LIST_REQUEST",
    GET_CATEGORY_LIST_SUCCESS: "GET_CATEGORY_LIST_SUCCESS",
    GET_CATEGORY_LIST_FAILURE: "GET_CATEGORY_LIST_FAILURE",


    ADD_CATEGORY_REQUEST: "ADD_CATEGORY_REQUEST",
    ADD_CATEGORY_SUCCESS: "ADD_CATEGORY_SUCCESS",
    ADD_CATEGORY_FAILURE: "ADD_CATEGORY_FAILURE",


    DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
    DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
    DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",

    CREATE_MEME_PUBLISH_REQUEST: "CREATE_MEME_PUBLISH_REQUEST",
    CREATE_MEME_PUBLISH_SUCCESS: "CREATE_MEME_PUBLISH_SUCCESS",
    CREATE_MEME_PUBLISH_FAILURE: "CREATE_MEME_PUBLISH_FAILURE",

    UPLOAD_IMAGE_REQUEST: "UPLOAD_IMAGE_REQUEST",
    UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
    UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",

    GET_CAT_REQUEST: "GET_CAT_REQUEST",
    GET_CAT_SUCCESS: "GET_CAT_SUCCESS",
    GET_CAT_FAILURE: "GET_CAT_FAILURE",

    TOTAL_CATEGORIES_REQUEST: "TOTAL_CATEGORIES_REQUEST",
    TOTAL_CATEGORIES_SUCCESS: "TOTAL_CATEGORIES_SUCCESS",
    TOTAL_CATEGORIES_FAILURE: "TOTAL_CATEGORIES_FAILURE",

    TOTAL_MEME_COUNT_REQUEST: "TOTAL_MEME_COUNT_REQUEST",
    TOTAL_MEME_COUNT_SUCCESS: "TOTAL_MEME_COUNT_SUCCESS",
    TOTAL_MEME_COUNT_FAILURE: "TOTAL_MEME_COUNT_FAILURE",

    TOTAL_REVENUE_COUNT_REQUEST: "TOTAL_REVENUE_COUNT_REQUEST",
    TOTAL_REVENUE_COUNT_SUCCESS: "TOTAL_REVENUE_COUNT_SUCCESS",
    TOTAL_REVENUE_COUNT_FAILURE: "TOTAL_REVENUE_COUNT_FAILURE",

    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",

    DELETE_MEME_REQUEST: "DELETE_MEME_REQUEST",
    DELETE_MEME_SUCCESS: "DELETE_MEME_SUCCESS",
    DELETE_MEME_FAILURE: "DELETE_MEME_FAILURE",

    SET_DEFAULT_PRICE_REQUEST:"SET_DEFAULT_PRICE_REQUEST",
    SET_DEFAULT_PRICE_SUCCESS:"SET_DEFAULT_PRICE_SUCCESS",
    SET_DEFAULT_PRICE_FAILURE:"SET_DEFAULT_PRICE_FAILURE",

    GET_DEFAULT_PRICE_REQUEST:"GET_DEFAULT_PRICE_REQUEST",
    GET_DEFAULT_PRICE_SUCCESS:"GET_DEFAULT_PRICE_SUCCESS",
    GET_DEFAULT_PRICE_FAILURE:"GET_DEFAULT_PRICE_FAILURE",

    UPLOAD_FONT_REQUEST:"UPLOAD_FONT_REQUEST",
UPLOAD_FONT_SUCCESS:"UPLOAD_FONT_SUCCESS",
UPLOAD_FONT_FAILURE:"UPLOAD_FONT_FAILURE",

FETCH_FONT_REQUEST:"FETCH_FONT_REQUEST",
FETCH_FONT_SUCCESS:"FETCH_FONT_SUCCESS",
FETCH_FONT_FAILURE:"FETCH_FONT_FAILURE",

DELETE_FONT_REQUEST:"DELETE_FONT_REQUEST",
DELETE_FONT_SUCCESS:"DELETE_FONT_SUCCESS",
DELETE_FONT_FAILURE:"DELETE_FONT_FAILURE",

    TOGGLE_STATE:"TOGGLE_STATE"



}