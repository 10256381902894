import React from 'react';
import './App.css';
import { Router, Switch } from 'react-router-dom'
import history from './components/config/history'
import AdminPublicRoute from './components/common/AdminPublicRoute';
import adminLogin from './components/auth/admin/adminLogin';
import dashboard from './components/dashboard/dashboard';
import categories from './components/dashboard/categories';
import createMeme from './components/dashboard/createMeme';
import memes from './components/dashboard/memes';
import settings from './components/dashboard/settings';
import UserPublicRoute from './components/common/UserPublicRoute';
import PrivateRoute from './components/common/PrivateRoute'



function App() {
  return (
    <Router history={history}>
    <Switch>
      <AdminPublicRoute path='/' exact component={adminLogin} /> 
      <PrivateRoute path='/dashboard' exact component={dashboard} /> 
      <PrivateRoute path='/categories' exact component={categories} /> 
      <PrivateRoute path='/createMeme' exact component={createMeme} /> 
      <PrivateRoute path='/memes' exact component={memes} /> 
      <PrivateRoute path='/settings' exact component={settings} /> 
      
    </Switch>
  </Router>
  );
}

export default App;
